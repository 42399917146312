import * as React from 'react'

// export interface IAppProps { }
export default class PleaseWait extends React.Component<any, any> {
  public interval: NodeJS.Timer
  constructor(props: any) {
    super(props)
    this.state = { count: 0 }
    this.interval = setInterval(this.updateState, 500)
  }
  public updateState = () => {
    let count = this.state.count + 1
    count = count > 3 ? 0 : count
    this.setState({ count })
  }
  public componentWillUnmount = () => {
    clearInterval(this.interval)
  }
  public renderDot = (doRender: boolean) => {
    if (doRender) {
      return <span className="fadeIn">.</span>
    }
    return <span style={{ visibility: 'hidden' }}>.</span>
  }
  public render() {
    const { count } = this.state
    return (
      <React.Fragment>
        <span>Please wait</span>
        {count >= 0 && <span className="fadeIn" />}
        {this.renderDot(count >= 1)}
        {this.renderDot(count >= 2)}
        {this.renderDot(count >= 3)}
      </React.Fragment>
    )
  }
}
