import Axios from 'axios'
import getConfig from 'next/config'
import { GERS_SERVICE, ORIGIN_CODE_WEB } from '../../../../../settings/variables'
import { IGersOrder } from '../../../../../../pages/checkout'
import logProviderFactory from '../../../../../utils/logs/logProviderFactory'
const { publicRuntimeConfig } = getConfig()
export const CMS_API = publicRuntimeConfig.CMS_API || 'http://localhost:1337'
export const CACHE_ENDPOINT = publicRuntimeConfig.CACHE_ENDPOINT || ''
export interface IPreSubmitOrderResponse {
  resultCode: string
  order: IGersOrder | null
}

export interface IGersPlaceOrderResponse {
  error: string
  data: IGersPlaceOrder | null
}

export interface IGersPlaceOrder {
  delDocNumber: string
  errorCode: string
  errorMessage: string
}
export interface IMethodOfPayment {
  amount: number
  authApprovalCode?: string
  authDate?: Date
  authRefNum?: string
  cardNum?: string
  mopCode: string
  promoCode?: string
  promoDescription?: string
}

/// PRE ORDER SAVE INTERFACES ///
// There's no v1 request interface. This is to be consistent with the related method name.
export interface IGersPreOrderSaveRequestV2 {
  billToInfo: any
  merceCartId: string
  pickupDeliveryType: string
  shipToInfo: any
}

export interface IGersPreOrderSaveRequestV3 extends IGersPreOrderSaveRequestV2 {
  methodsOfPayment: IMethodOfPayment[]
  customerCode?: string
  needByDate?: string
}
/// PRE ORDER SAVE INTERFACES ///

/// PLACE ORDER SAVE INTERFACES ///
// There's no v1 request interface. This is to be consistent with the related method name.
export interface IGersPlaceOrderRequestV2 {
  merceCartId: string
}

export interface IGersPlaceOrderRequestV3 extends IGersPlaceOrderRequestV2 {
  methodsOfPayment: IMethodOfPayment[]
}

const ENDPOINTS = {
  PLACE_ORDER: 'orders/placeOrder',
  PRE_ORDER_SAVE_V3: 'orders/v3/preOrderSave',
  PLACE_ORDER_V3: 'orders/v3/submitPlaceOrder',
}

const getBodyWithOriginCode = (params: IGersPreOrderSaveRequestV2 | IGersPlaceOrderRequestV2) => {
  return {
    ...params,
    originCode: ORIGIN_CODE_WEB,
  }
}

/**
 * preOrderSave v3: Persist and validate order details in GERS, to then make the actual placeOrder.
 * V3 adds multiple methods of payments.
 *
 * @param params: IGersPlaceOrderRequestV3
 */
const preOrderSaveV3 = async (params: IGersPreOrderSaveRequestV3): Promise<IGersPlaceOrderResponse> => {
  console.log('on preOrderSaveV3')
  try {
    logProviderFactory.logMessage(`Calling preOrderSaveV3 for merceCartId: ${[params.merceCartId]}.`, params)
    const endpoint = `${GERS_SERVICE}/${ENDPOINTS.PRE_ORDER_SAVE_V3}`
    const response = await Axios.post(endpoint, getBodyWithOriginCode(params))
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    console.log(e)
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

/**
 * PlaceOrder v3: Call the Place Order stored proc in GERS for given merceCartId.
 * V3 adds multiple methods of payments.
 *
 * @param params: IGersSubmitPlaceOrderRequestV3
 */
const placeOrderV3 = async (params: IGersPlaceOrderRequestV3): Promise<IGersPlaceOrderResponse> => {
  console.log('on placeOrderV3')
  try {
    logProviderFactory.logMessage(`Calling placeOrderV3 for merceCartId: ${[params.merceCartId]}.`, params)
    const endpoint = `${GERS_SERVICE}/${ENDPOINTS.PLACE_ORDER_V3}`
    const response = await Axios.post(endpoint, getBodyWithOriginCode(params))
    logProviderFactory.logMessage(`Success in placeOrderV3 for merceCartId: ${[params.merceCartId]}.`, response.data)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    logProviderFactory.logError(`Error in placeOrderV3 for merceCartId: ${[params.merceCartId]}.`, e)
    console.log(e)
    throw e
  }
}

export default {
  preOrderSaveV3,
  placeOrderV3,
}
