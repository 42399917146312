import getConfig from 'next/config'
import getDefaultCmsTransport from '../../../../../services/api/cmsInterceptor'
import { ITransport } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import { IMorCartPrice } from '../../../Context/MorCartContext'
import { ORIGIN_CODE_WEB } from '../../../../../settings/variables'

const { publicRuntimeConfig } = getConfig()
export const CACHE_ENDPOINT = publicRuntimeConfig.CACHE_ENDPOINT || ''

const ENDPOINTS = {
  TERMS_AND_CONDITIONS: 'v1/terms',
  FINANCE_OPTIONS_V2: 'v2/finance/options',
}
export interface IFinanceOptionsRequest {
  cardType: string
  remaningCartBalance: number
  amountRequested: number
  storeCode?: string
  deliveryCharge: number
  taxCharge: number
  recycleCharge: number
  safeguardCharge?: number
  customOrderDeposit: number
  currentDeposit: number
  pickupDeliveryStateCode: string
  originCode: 'WEB'
}
export interface IFinancePromotionOffer {
  asCode: string
  financeCompanyName: string
  promotionCode: string
  promotionDescription: string
  deferredInterestMonths: string
  minimumFinanceAmount: number
  financedAmount: number
  monthlyPayment: number
  qualifiedForPromo: boolean
  promoDescription1: string
  promoDescription2: string
  promoDescription3: string
}

export interface IFinancedPayment {
  authCode?: string
  accountNumber?: string
  promotionCode: string
  promotionDescription: string
  financedAmount: number
}

export interface IFinanceOptionsResponse {
  error: string
  data: {
    financeAmount: number
    remainingBalance: number
    openToBuy: number
    promotionOffers: IFinancePromotionOffer[]
    status: string
    message: string
  }
}

export interface ITermsAndConditionsLine {
  name: string
  text: string
  sequence: number
}

export interface ITermsAndConditionsResponse {
  error: string
  data: ITermsAndConditionsLine[]
}

export enum IFinanceCardType {
  SYF = 'SYF',
}

const getFinanceOptions = async (transport: ITransport, cartPrice: IMorCartPrice, pickupDeliveryStateCode: string) => {
  console.log(`calling v2/finance/options: cartPrice.customOrderDepAmount: ${cartPrice.customOrderDepAmount}`)
  console.log(`and cartPrice.shipping: ${cartPrice.shipping} - cartPrice.delDiscAmount: ${cartPrice.delDiscAmount}`)
  console.log(`and cartPrice.taxCharge: ${cartPrice.taxCharge} - cartPrice.taxDiscAmount: ${cartPrice.taxDiscAmount}`)
  try {
    const endpoint: string = `/${ENDPOINTS.FINANCE_OPTIONS_V2}`
    const request: IFinanceOptionsRequest = {
      amountRequested: cartPrice.orderTotal,
      cardType: IFinanceCardType.SYF,
      remaningCartBalance: cartPrice.orderTotal,
      deliveryCharge: cartPrice.shipping - cartPrice.delDiscAmount,
      taxCharge: cartPrice.taxCharge - cartPrice.taxDiscAmount,
      recycleCharge: cartPrice.recyclingCharge,
      safeguardCharge: cartPrice.safeguardFee,
      customOrderDeposit: cartPrice.customOrderDepAmount,
      pickupDeliveryStateCode,
      currentDeposit: 0,
      originCode: ORIGIN_CODE_WEB,
    }
    const response = await transport.post(endpoint, request)
    return { error: '', data: response.data }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const getTermsAndConditions = async (cardType?: string): Promise<ITermsAndConditionsResponse> => {
  try {
    const endpoint: string = cardType
      ? `/${ENDPOINTS.TERMS_AND_CONDITIONS}?cardType=${cardType}`
      : `/${ENDPOINTS.TERMS_AND_CONDITIONS}`
    const transport: ITransport = getDefaultCmsTransport(false, true, true)
    const response: ITermsAndConditionsResponse = await transport.get(endpoint)
    return { error: '', data: response.data }
  } catch (e) {
    return {
      error: e.response?.data?.errorMessage ? e.response.data.errorMessage : 'Unable to retrieve terms and conditions.',
      data: e.response?.status || {},
    }
  }
}

export default {
  getFinanceOptions,
  getTermsAndConditions,
}
