import moment from 'moment'

export enum DATE_FORMATS {
  YYYYMMDD = 'YYYY-MM-DD',
  MMDDYYYY = 'MM/DD/YYYY',
  MMDD = 'MM/DD',
}

/**
 * Return the moment object, this method prevent moment deprecation warning and work for all browsers
 * @param date {Date | string | moment.Moment}
 * @returns
 */
const getSafeMomentObject = (date: Date | string | moment.Moment): moment.Moment => {
  const ISO_8601_DATE_REGEX = /^\d{2}\/\d{2}\/\d{4}$/

  // Add compatibility to ISO 8601 dates for all browsers
  if (typeof date === 'string' && ISO_8601_DATE_REGEX.test(date)) {
    return moment(date, ['MM/DD/YYYY', 'M/D/YYYY'])
  }

  return moment(date)
}

const formatDate = (dateToFormat: string | Date | null | undefined, formatString: DATE_FORMATS) => {
  return dateToFormat ? getSafeMomentObject(dateToFormat).format(formatString) : ''
}

const formatDateForAPI = (date: any) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

/**
  Next months days + remaining days in current month
*/
const getDatePickerNumberOfDays = () => {
  const currentDay = moment(new Date())
  const endDayOfMonth = moment(new Date()).endOf('month')
  const remainingDaysInCurrentMonth = endDayOfMonth.diff(currentDay, 'day')
  const daysInNextMonth = moment(new Date())
    .add(1, 'month')
    .startOf('month')
    .daysInMonth()

  return remainingDaysInCurrentMonth + daysInNextMonth
}

const getDatesInBetween = (start: Date | moment.Moment, end: Date | moment.Moment) => {
  const startDate = moment(start)
  const endDate = moment(end)

  const now = startDate.clone()
  const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(DATE_FORMATS.YYYYMMDD))
    now.add(1, 'days')
  }

  return dates
}

const convertToUTC = (date?: string): Date | null => {
  if (!date) {
    return null
  }
  const newDate = new Date(date)
  return new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000)
}

export { getDatePickerNumberOfDays, formatDateForAPI, getDatesInBetween, convertToUTC, formatDate, getSafeMomentObject }
