import { ITransport } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import CACHE_CONTSTANT from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/cache/constants'
import { ICollection } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'

export interface ICollectionMetaResponse {
  error: ''
  data: ICollection
}

export interface ICollectionRequestParams {
  route: string[]
}

// ----------------------------------------------------- pages -----------------------------------------------------------------------
const getCollection = async (transport: ITransport, params: ICollectionRequestParams) => {
  try {
    const response = await transport.get(`/collections/${params.route}`, {
      headers: { cacheKey: `${CACHE_CONTSTANT.FRONTEND.PIM.COLLECTIONS}-${params.route}` },
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getCollection,
}
