import axios from 'axios'
import getConfig from 'next/config'
import { GERS_SERVICE } from '../../../../../settings/variables'
const { publicRuntimeConfig } = getConfig()
export const CMS_API = publicRuntimeConfig.CMS_API || 'http://localhost:1337'
export const CACHE_ENDPOINT = publicRuntimeConfig.CACHE_ENDPOINT || ''

export enum PriceType {
  STORE = 'S',
  WEB = 'W',
}
export interface IGersPriceAndInventoryResponse {
  error: string
  data: IGersPriceAndInventorySummary | null
}

export interface IGersPriceAndInventoryBatchResponse {
  error: string
  data: IGersPriceAndInventorySummary[] | null
}

export interface IGersPriceAndInventorySummary {
  sku: string
  storeOrZipCode: string
  unitPrice: number
  availableQuantity?: string
  secondaryAvailableQuantity: string
  leadTime: string
  customerMessage: string
  primaryWarehouse?: string
  primaryQty?: string
  secondaryWarehouse?: string
  secondaryQty?: string
  tertiaryWarehouse?: string
  tertiaryQty?: string
  errorCode: string
  errorMessage: string
}

export interface IPriceInventory {
  sku: string
  storeCode: string
}

export interface IPriceInventorySkuBatch {
  priceType: PriceType
  storeCode: string
  skus: string[]
}

// If leadTime = 143 means 999 days. This is a default value for unavailable products.
export const isItemUnavailable = (item?: IGersPriceAndInventorySummary) => {
  if (item) {
    const leadTime: number = parseInt(item.leadTime || '0')
    const primaryQty: number = parseInt(item.primaryQty || '0')
    const secondaryQty: number = parseInt(item.secondaryQty || '0')
    const tertiaryQty: number = parseInt(item.tertiaryQty || '0')
    const isThereInventory = primaryQty !== 0 || secondaryQty !== 0 || tertiaryQty !== 0
    return !((!isThereInventory && leadTime > 0 && leadTime !== 143) || isThereInventory)
  }
  return false
}

const ENDPOINTS = {
  PRICES: 'prices',
}

const getBatchPriceAndInventoryBySku = async (
  params: IPriceInventorySkuBatch,
): Promise<IGersPriceAndInventoryBatchResponse> => {
  try {
    const endpoint = `${GERS_SERVICE}/${ENDPOINTS.PRICES}`
    const response = await axios.post(endpoint, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getBatchPriceAndInventoryBySku,
}
