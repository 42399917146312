import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import swatchesCss from './Swatches.scss'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { IActiveOptions } from '../PDP'
import {
  IProduct,
  IProductAttributeField,
} from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import LazyImage from '../../../Common/Image/LazyImage'

export interface ISwatch {
  option: string
  url: string
  customOptionValueId: number
  sequence: number
}

export interface IAttributeSwatch {
  code: string
  swatches: ISwatch[]
}

export interface ISwatchOption {
  label: string
  product?: IProduct
}

interface ISwatchesProps {
  options: ISwatchOption[]
  field: IProductAttributeField
  isOptionSelected: (code: string) => boolean
  groupSwatches?: IAttributeSwatch[]
  onClickSwatch: (option: ISwatchOption, code: string, customOptionValueId: number) => void
  selectedOptions?: IActiveOptions[]
  isCustomAddon?: boolean
}

const getSwatch = (option: ISwatchOption, code: string, groupSwatches?: IAttributeSwatch[]) => {
  if (groupSwatches) {
    const attribute = groupSwatches.find(s => s.code === code)
    if (attribute?.swatches?.length) {
      const optionSwatch = attribute.swatches.find(o => o.option.toLowerCase() === option.label.toLowerCase())
      return optionSwatch
    }
  }
  return null
}

const Swatches = (props: ISwatchesProps) => {
  /**
   * Maps the custom addons values to the required option object.
   */
  const mapAddOnsValuesToOptions = (options: ISwatchOption[], code: string) => {
    if (props.isCustomAddon && groupSwatches) {
      const attribute = groupSwatches.find(s => s.code === code)
      return attribute?.swatches.map((s: ISwatch) => {
        return {
          label: s.option,
        }
      })
    }
    return options
  }

  const isCustomOptionSelected = (option: ISwatchOption, code: string) => {
    if (props.selectedOptions?.length) {
      return props.selectedOptions.find(sO => sO?.code === code && sO.value.includes(option.label))
    }
    return null
  }

  const { options, field, groupSwatches, isOptionSelected, onClickSwatch } = props
  return (
    <div className={swatchesCss.swatchesSelector}>
      {mapAddOnsValuesToOptions(options, field.attribute.code)?.map((option: ISwatchOption, index: number) => {
        const swatch = getSwatch(option, field.attribute.code, groupSwatches)
        const optionSelected =
          isOptionSelected(`${field.attribute.code}:${option.label}`) ||
          isCustomOptionSelected(option, field.attribute.code)
        return swatch ? (
          <div key={index} className={swatchesCss.swatchContainer}>
            <div
              id={swatch.customOptionValueId.toString()}
              onClick={() =>
                optionSelected ? null : onClickSwatch(option, field?.attribute?.code, swatch.customOptionValueId)
              }
              className={`${swatchesCss.thumbnailOptions} ${optionSelected ? swatchesCss.active : ''}`}
            >
              {optionSelected && (
                <FontAwesomeIcon
                  style={{
                    width: '32px',
                    marginLeft: '24px',
                    marginTop: '2px',
                    stroke: 'black',
                    strokeWidth: 20,
                    position: 'absolute',
                    zIndex: 1,
                  }}
                  icon={faCheck}
                  color="white"
                  title="Option Selected"
                  role="img"
                  aria-label="Option Selected"
                />
              )}
              <LazyImage src={swatch.url} alt={swatch.option} width={80} height={80} objectFit="contain" />
            </div>
            <div className={swatchesCss.swatchLabel}>{swatch.option}</div>
          </div>
        ) : (
          <></>
        )
      })}
    </div>
  )
}

export default Swatches
