import axios from 'axios'
import {
  DATADOG_API_KEY,
  DATADOG_APPLICATION_NAME,
  DATADOG_ENABLE_LOGS,
  DATADOG_HOST,
} from '../../../settings/variables'
import { ILogProviderInterface } from '../logProviderFactory'

interface Error {
  stack?: string
  message: string
  config?: {
    baseURL: string
    data: any
  }
}

const ENV = process.env.NODE_ENV || 'development'
const LOG_HOST_NAME = ENV !== 'development' ? `deploy_${ENV}` : 'localhost'
const PATH = `/api/v2/logs?dd-api-key=${DATADOG_API_KEY}&ddsource=nodejs&service=${DATADOG_APPLICATION_NAME}`

const httpTransportOptions = {
  host: DATADOG_HOST,
  path: PATH,
  ssl: true,
  hostname: LOG_HOST_NAME,
  service: DATADOG_APPLICATION_NAME,
  ddsource: 'nodejs',
  ddtags: `env:${ENV}`,
}

const datadogTransporter = async (payload: any): Promise<any> => {
  if (!DATADOG_ENABLE_LOGS) {
    return
  }

  const { level, message, timestamp, metadata, sendLog, details } = payload
  const messageDate = {
    message,
    metadata,
  }

  if (sendLog || level) {
    const data = [
      {
        level: level,
        details,
        message: messageDate,
        service: httpTransportOptions.service,
        metadata: metadata,
        ddsource: httpTransportOptions.ddsource,
        ddtags: httpTransportOptions.ddtags,
        timestamp: timestamp,
      },
    ]
    return axios.post(`${httpTransportOptions.host}${httpTransportOptions.path}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

/**
 * Call this at the start of your application
 */
const initialize = () => {
  // Nothing to initialize with console logging
}

/**
 * Generic method where you can pass anything in
 * @param {*} config { name:string, settings: object }
 */
const hook = () => {
  // TODO
}

/**
 * Log generic messages
 * @param {*} message
 */
const logMessage = async (message: string, data?: any) => {
  const payload = {
    level: 'info',
    message,
    timestamp: Date.now(),
    metadata: {},
    sendLog: true,
    details: data || {},
  }
  await datadogTransporter(payload)
}

/**
 * Log error messages
 * @param {*} message string
 * @param {*} error Error
 */
const logError = async (message: string, error?: Error) => {
  const details = {
    message: error?.message,
    stack: error?.stack,
    baseURL: '',
    data: '',
  }
  if (error?.config) {
    details.baseURL = error.config.baseURL
    details.data = error.config.data
  }
  const payload = {
    level: 'error',
    message,
    timestamp: Date.now(),
    metadata: {},
    sendLog: true,
    details,
  }
  await datadogTransporter(payload)
}

export default { initialize, logMessage, logError, hook } as ILogProviderInterface
