import { IProduct, ICoupon } from '../PDP'
import { IPageContainer } from '../OmniPage/interfaces'

import {
  IVendorShippingOptions as IVendorShippingOptionsAlias,
  IVendorShipping as IVendorShippingAlias,
  ICartItem as ICartItemAlias,
  ICartPrice as ICartPriceAlias,
  ICartShippingRule as ICartShippingRuleAlias,
  ICart as ICartAlias,
  CART_COLLECTION_TYPE,
  ICartCollection as ICartCollectionAlias,
  ICartSummary as ICartSummaryAlias,
  IIdentity as IIdentityAlias,
} from '../Common/Interfaces/Strapi/Cart'
import OmniCart from './OmniCart'
import CartItemCarousel from './CartItemCarousel/CartItemCarousel'

export { OmniCart, CartItemCarousel }

export interface ICartEventProps {
  onRemoveItemFromCart: (item: ICartItem) => void
  onQuantityUpdated: (item: ICartItem) => void
  onNavigateToProduct: (product: IProduct) => void
  onContinueShopping?: () => void
  onCheckoutButtonPushed?: () => void
  onClearCart?: () => void
  onClearSelection?: (productIds: string[]) => void
}

export interface IAlternateCheckoutOption {
  renderButton: () => JSX.Element
}

export interface ICalculateCartProps {
  cart?: ICartItem[]
  address?: any
  coupon?: string
}

export interface ICartItemProps {
  events?: ICartEventProps
  renderShipsBySection?: (product: IProduct, quantity?: number) => JSX.Element
  defaultThumbnail?: string
  onSelectionHandler?: Function
  selectionIDs?: string[]
  allowBulkSelection?: boolean
}

export enum ICartLayoutColumnType {
  CART = 'CART',
  CONTAINER = 'CONTAINER',
  SUMMARY = 'SUMMARY',
  CHECKOUT_BTN = 'CHECKOUT_BTN',
  RENDER_PROPS = 'RENDER_PROPS',
}

export interface ICartConfiguration {
  id: string
  _id: string
  title: string
  defaultShippingRuleSchema: { [x: string]: any }
}

export interface ICartLayout {
  id: string
  _id: string
  title: string
  layout: any
  enabled: boolean
  pagecontainer: IPageContainer
}

export interface IApiGetCartResponse {
  error: string
  data: ICartSummary | null
}

export interface IApiCouponResponse {
  error: string
  data: ICoupon | null
}
export interface IApiCouponsResponse {
  error: string
  data: ICoupon[] | null
}

export interface IApiCartGenericRequestParameters {
  identity?: IIdentity
  populate: boolean
}
export interface IApiCartItemRequestParameters extends IApiCartGenericRequestParameters {
  productId: string
  metaData?: { [x: string]: any }
  appendQuantity?: number
}

export interface IApiCartAddCouponRequestParameters extends IApiCartGenericRequestParameters {
  couponCode: string
}

export interface IApiQuantityCartRequestParameters extends IApiCartGenericRequestParameters {
  productId: string
  quantity: number
  metaData?: { [x: string]: any }
}

// ----------------------------------------- ALIASES ----------------------------------

export type IVendorShippingOptions = IVendorShippingOptionsAlias
export type IVendorShipping = IVendorShippingAlias
export type ICartItem = ICartItemAlias
export type ICartPrice = ICartPriceAlias
export type ICartShippingRule = ICartShippingRuleAlias
export type ICart = ICartAlias
export { CART_COLLECTION_TYPE }
export type ICartCollection = ICartCollectionAlias
export type ICartSummary = ICartSummaryAlias
export type IIdentity = IIdentityAlias
