import * as React from 'react'
import { useEffect, useRef } from 'react'
const Hammer = typeof window !== 'undefined' ? require('hammerjs') : undefined

interface IGestureRecognizerProps {
  onSwipeLeft?: (event: any) => void
  onSwipeRight?: (event: any) => void
}

const GestureRecognizer: React.FunctionComponent<IGestureRecognizerProps> = props => {
  const { onSwipeLeft, onSwipeRight } = props
  const element = useRef<HTMLDivElement>(null)

  useEffect((): any => {
    if (element.current) {
      const hammerManager = new Hammer(element.current, {
        touchAction: 'auto',
        inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
        recognizers: [
          [
            Hammer.Swipe,
            {
              direction: Hammer.DIRECTION_HORIZONTAL,
            },
          ],
        ],
      })

      hammerManager.off('swipeleft')
      hammerManager.off('swiperight')
      hammerManager.on('swipeleft', function(event: any) {
        onSwipeLeft && onSwipeLeft(event)
      })
      hammerManager.on('swiperight', function(event: any) {
        onSwipeRight && onSwipeRight(event)
      })

      return () => {
        hammerManager.stop()
        hammerManager.destroy()
      }
    }
  }, [])

  return <div ref={element}>{props.children}</div>
}

export default GestureRecognizer
