import { ITransport } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import CACHE_CONTSTANT from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/cache/constants'

export interface IProductGroupParams {
  id: string
}

const getProductGroup = async (transport: ITransport, params: IProductGroupParams) => {
  try {
    const query: string = `
      {
        productgroup(id:"${params.id}"){
          title
          metadata
          leadAttribute {
            id
            code
            title
          }
          attributes {
            id
            code
            title
          }
        }
      }
    `
    const response = await transport.post('/graphql', {
      query,
      cacheKey: `${CACHE_CONTSTANT.FRONTEND.PIM.PRODUCTS}-pg-${params.id}`,
    })

    return {
      error: '',
      data: response.data.data.productgroup,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getProductGroup,
}
