import STORELOCATOR from './Pricing/storelocator'
import DELIVERY from './Cart/delivery'
import CART from './Cart/cart'
import INVENTORY from './Pricing/inventory'
import ORDER from './Cart/order'
import CUSTOMER from './Associate/customer'
import SYNCHRONY_FINANCIAL from './Associate/synchrony-financial'
import VOUCHER from './Checkout/voucher'

export const GERS_API_CALLS = {
  STORELOCATOR,
  DELIVERY,
  CART,
  INVENTORY,
  ORDER,
  CUSTOMER,
  SYNCHRONY_FINANCIAL,
  VOUCHER,
}
