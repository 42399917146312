import { IGenericLayoutConfigurationRow } from '../../interfaces'
import { ICartLayoutColumnType } from '..'

export default (): IGenericLayoutConfigurationRow[] => {
  return [
    {
      columns: [
        {
          span: '8',
          type: ICartLayoutColumnType.CONTAINER,
          feature: [''],
          parameter: [''],
          rows: [
            {
              columns: [
                {
                  span: '12',
                  type: ICartLayoutColumnType.CART,
                  parameter: [''],
                  feature: [''],
                  rows: [],
                },
              ],
            },
          ],
        },
        {
          span: '4',
          type: ICartLayoutColumnType.CONTAINER,
          feature: [''],
          parameter: [''],
          rows: [
            {
              columns: [
                {
                  span: '12',
                  type: ICartLayoutColumnType.SUMMARY,
                  parameter: [''],
                  feature: [''],
                  rows: [],
                },
                {
                  span: '12',
                  type: ICartLayoutColumnType.CHECKOUT_BTN,
                  parameter: ['<i class="fas fa-lock fa-pull-left"></i>SECURE CHECKOUT'],
                  classes: [],
                  feature: [''],
                  rows: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
