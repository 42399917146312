import * as React from 'react'
import Loader from '../Common/Loader'
import { Row, Col } from '../Common/Layout'
import OrderSummary from '../Common/Widgets/OrderSummary/OrderSummary'
import { IProduct, ICoupon } from '../PDP'
import ThemedButton from '../Common/Button/Button'
import { IGenericLayoutConfigurationRow, IGenericLayoutConfigurationColumn, MERCE_CLASS_NAMES } from '../interfaces'
import defaultCartLayoutRows from './Layout/DefaultCartLayout'
import css from './OmniCart.module.scss'
import CartItems from './CartItems/CartItems'
import { ICartItem, ICartPrice, ICartEventProps, ICartLayoutColumnType, ICartItemProps } from '.'

interface IProps {
  items: ICartItem[]
  events: ICartEventProps
  cartTotal: ICartPrice
  coupon?: {
    getCoupon: () => ICoupon | null
    setCoupon: any
  }
  defaultLayout?: () => IGenericLayoutConfigurationRow[]
  renderProps: {
    renderShipsBySection?: (product: IProduct, quantity?: number) => JSX.Element
    renderCartItem?: (cartItem: ICartItem, props?: ICartItemProps) => JSX.Element
    [x: string]: any
  }
  defaultThumbnail?: string
  clearCartOption?: boolean
  sectionVisibility?: {
    [key: string]: boolean
  }
}

const BlockWrapper = (props: any) => {
  return <div className={css.marginBottom}>{props.children}</div>
}

export default class Cart extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props)
  }

  /// ------------------------------------------------------------------ DYNAMIC BUILD ------------------------------------------------
  public renderContainer = (col: IGenericLayoutConfigurationColumn) => {
    return (
      <React.Fragment>
        {col.rows.map((row: IGenericLayoutConfigurationRow, index: number) => {
          return this.renderRow(row, index)
        })}
      </React.Fragment>
    )
  }

  public renderRow = (row: IGenericLayoutConfigurationRow, index: number) => {
    return (
      <Row key={index}>
        {row.columns.map((c: IGenericLayoutConfigurationColumn, index2: number) => {
          return this.renderColumn(c, index2)
        })}
      </Row>
    )
  }

  public renderColumn = (column: IGenericLayoutConfigurationColumn, index: number) => {
    return (
      <Col md={parseInt(column.span, 10)} key={index}>
        {this.buildPdpComponent(column)}
      </Col>
    )
  }

  public renderCart = (_: IGenericLayoutConfigurationColumn) => {
    const { items, clearCartOption, sectionVisibility } = this.props
    return (
      <BlockWrapper>
        <CartItems
          items={items}
          events={this.props.events}
          renderShipsBySection={this.props.renderProps.renderShipsBySection}
          renderCartItem={this.props.renderProps.renderCartItem}
          defaultThumbnail={this.props.defaultThumbnail}
          clearCartOption={clearCartOption}
          sectionVisibility={sectionVisibility}
        />
      </BlockWrapper>
    )
  }

  public renderCheckoutButton = (col: IGenericLayoutConfigurationColumn) => {
    const classes: string[] = col.classes || []
    const buttonContent: string = col.parameter[0] ? col.parameter[0] : 'SECURE CHECKOUT'
    return (
      <BlockWrapper>
        <div className={`${classes.join(' ')} ${MERCE_CLASS_NAMES.CART_CHECKOUT_BUTTON}`}>
          <ThemedButton onClick={this.props.events.onCheckoutButtonPushed}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              dangerouslySetInnerHTML={{
                __html: buttonContent,
              }}
            />
          </ThemedButton>
        </div>
      </BlockWrapper>
    )
  }

  public renderOrderSummary = () => {
    return (
      <BlockWrapper>
        <OrderSummary cartPrice={this.props.cartTotal} />
      </BlockWrapper>
    )
  }

  public buildRenderProps = (col: IGenericLayoutConfigurationColumn) => {
    const param: any = col.parameter[0] as any
    const renderProps: any = this.props.renderProps as any
    const method: any = renderProps[param] as any
    return <BlockWrapper>{method()}</BlockWrapper>
  }

  public buildPdpComponent = (col: IGenericLayoutConfigurationColumn) => {
    switch (col.type) {
      case ICartLayoutColumnType.CART:
        return this.renderCart(col)
      case ICartLayoutColumnType.CHECKOUT_BTN:
        return this.renderCheckoutButton(col)
      case ICartLayoutColumnType.CONTAINER:
        return this.renderContainer(col)
      case ICartLayoutColumnType.SUMMARY:
        return this.renderOrderSummary()
      case ICartLayoutColumnType.RENDER_PROPS:
        return this.buildRenderProps(col)
    }
    return <div />
  }

  // ------------------------------------------------------------------- END DYNAMIC BUILD ----------------------------------------------

  public render() {
    const { items } = this.props
    const defaultLayout: any = this.props.defaultLayout || defaultCartLayoutRows
    if (!items) {
      return <Loader center={true} spinner="cart" />
    }
    return (
      <React.Fragment>
        <div className={css.cart}>
          {defaultLayout().map((row: IGenericLayoutConfigurationRow, index: number) => {
            return this.renderRow(row, index)
          })}
        </div>
      </React.Fragment>
    )
  }
}
