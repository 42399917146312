import * as React from 'react'
import { ICartPrice } from '../../../Cart'
import CouponField from '../../Coupon/Coupon'
import { MERCE_CLASS_NAMES } from '../../../interfaces'
import css from './OrderSummary.module.scss'

export interface IProps {
  cartPrice: ICartPrice | null
}

interface IPreloadTotal {
  subtotal: string
  total: string
  tax: string
  shipping: string
  coupon: string
  rate: string
  save: string
}

export default class OrderSummary extends React.Component<IProps, any> {
  public renderShippingClass = () => {
    if (this.props.cartPrice) {
      return this.props.cartPrice.shipping === 0 ? css.danger : ''
    }
    return ''
  }
  public renderCouponClass = () => {
    if (this.props.cartPrice) {
      return this.props.cartPrice.coupon < 0 ? css.danger : ''
    }
    return ''
  }
  public getLoadingTotal = (): IPreloadTotal => {
    if (!this.props.cartPrice) {
      return {
        total: 'Loading...',
        subtotal: 'Loading...',
        tax: 'Loading...',
        shipping: 'Loading...',
        coupon: '',
        save: '',
        rate: '',
      }
    }
    const { tax, subTotal, shipping, rate, coupon } = this.props.cartPrice
    return {
      subtotal: `$${subTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      shipping:
        shipping === 0
          ? 'FREE'
          : '$' +
            this.props.cartPrice.shipping.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
      tax:
        tax === -1
          ? 'N/A'
          : `$${tax.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
      total: `$${(subTotal + shipping + coupon + (tax === -1 ? 0 : tax)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      coupon:
        coupon === 0
          ? ''
          : `-$${(-coupon).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
      save:
        coupon === 0
          ? ''
          : `$${(-coupon).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
      rate: rate === 0 ? '' : rate.toString() + '%',
    }
  }

  public render() {
    const { total, subtotal, tax, shipping, coupon, save } = this.getLoadingTotal()
    return (
      <div className={`${css.summary} makeStick ${MERCE_CLASS_NAMES.CART_ORDER_SUMMARY}`}>
        <h3 className={css.title}>Order Summary</h3>
        <CouponField />
        <div className={css.body}>
          <div className={css.item}>
            <div className={`${css.label} ${MERCE_CLASS_NAMES.CART_SUBTOTAL_TITLE}`}>Subtotal:</div>
            <div className={`${css.value} ${MERCE_CLASS_NAMES.CART_SUBTOTAL_VALUE}`}>{subtotal}</div>
          </div>
          <div className={css.item}>
            <div className={`${css.label} ${MERCE_CLASS_NAMES.CART_SHIPPING_TITLE}`}>Shipping:</div>
            <div className={`${css.value} ${MERCE_CLASS_NAMES.CART_SHIPPING_VALUE} ${this.renderShippingClass()}`}>
              {shipping}
            </div>
          </div>
          {coupon && coupon !== '' && (
            <div className={css.item}>
              <div className={css.label}>Coupon:</div>
              <div className={`${css.value} ${this.renderCouponClass()}`}>{coupon}</div>
            </div>
          )}
          <hr />
          <div className={css.item}>
            <div className={`${css.label} ${MERCE_CLASS_NAMES.CART_TAX_TITLE}`}>Tax:</div>
            <div className={`${css.value} ${MERCE_CLASS_NAMES.CART_TAX_VALUE}`}>{tax}</div>
          </div>
          <div className={css.item}>
            <div className={`${css.label} ${MERCE_CLASS_NAMES.CART_TOTAL_TITLE}`}>Total:</div>
            <div className={`${css.value} ${MERCE_CLASS_NAMES.CART_TOTAL_VALUE}`}>{total}</div>
          </div>
          {save && save !== '' && (
            <div className={css.item}>
              <div className={`${css.label} ${css.danger}`}>You save:</div>
              <div className={`${css.value} ${css.danger}`}>{save}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
