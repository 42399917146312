import React from 'react'
import css from './ColorWheel.scss'

interface IColorWheelProps {
  style?: React.CSSProperties
}

const ColorWheel = (props: IColorWheelProps) => {
  return (
    <svg
      version="1.1"
      id="_x2014_ÎÓÈ_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      className={css.colorWheel}
      style={props.style ? { width: '20px', height: '20px' } : {}}
    >
      <g>
        <path className={css.st0} d="M29.4,29.4C16.42,42.39,8.32,56.42,3.56,74.16L100,100L29.4,29.4z" />
        <path className={css.st1} d="M3.56,74.16c-4.75,17.74-4.75,33.94,0,51.68L100,100L3.56,74.16z" />
        <path className={css.st2} d="M3.56,125.84c4.75,17.74,12.86,31.77,25.84,44.76L100,100L3.56,125.84z" />
        <path className={css.st3} d="M29.4,170.6c12.98,12.98,27.02,21.09,44.76,25.84L100,100L29.4,170.6z" />
        <path className={css.st4} d="M74.16,196.44c17.74,4.75,33.94,4.75,51.68,0L100,100L74.16,196.44z" />
        <path className={css.st5} d="M125.84,196.44c17.74-4.75,31.77-12.86,44.76-25.84L100,100L125.84,196.44z" />
        <path className={css.st6} d="M170.6,170.6c12.98-12.98,21.09-27.02,25.84-44.76L100,100L170.6,170.6z" />
        <path className={css.st7} d="M196.44,125.84c4.75-17.74,4.75-33.94,0-51.68L100,100L196.44,125.84z" />
        <path className={css.st8} d="M100,100l96.44-25.84c-4.75-17.74-12.86-31.77-25.84-44.76L100,100z" />
        <path className={css.st9} d="M170.6,29.4c-12.98-12.98-27.02-21.09-44.76-25.84L100,100L170.6,29.4z" />
        <path className={css.st10} d="M125.84,3.56c-17.74-4.75-33.94-4.75-51.68,0L100,100L125.84,3.56z" />
        <path className={css.st11} d="M74.16,3.56C56.43,8.32,42.39,16.42,29.4,29.4L100,100L74.16,3.56z" />
      </g>
    </svg>
  )
}

export default ColorWheel
