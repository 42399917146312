import * as React from 'react'
import CartContext, { ICartContextProviderProps } from '../../Cart/State/Context/CartContext'
import { THEME_BUTTON_TYPES } from '../Theme/ThemeWrapper'
import { ICoupon } from '../../PDP'
import { MERCE_CLASS_NAMES } from '../../interfaces'
import css from './Coupon.scss'

export enum COUPONVALIDATION {
  NONE = '',
  INACTIVE = 'Coupon is not active',
  INVALID = 'Coupon is invalid',
  MINSPEND = 'Must spend the minimum amount',
  NONEMAP = 'The item(s) in your cart do not qualify for a discount',
  VALID = 'Coupon has been applied',
  ERROR = 'Error, Please try again later',
  USED = 'Coupon already used',
}

export interface ICouponValidation {
  status: COUPONVALIDATION
  coupon?: ICoupon
}

const CouponField = (_: any) => {
  const { setCartCoupon, doUseCoupon, getCouponStatus, cartCoupon } = React.useContext(
    CartContext.Context,
  ) as ICartContextProviderProps
  const [activeCouponCode, setInternalCouponCode] = React.useState<string>('')
  const onCouponChange = (e: any) => {
    setInternalCouponCode(e.target.value)
  }
  React.useEffect(() => {
    if (cartCoupon) {
      setInternalCouponCode(cartCoupon.code)
    } else {
      setInternalCouponCode('')
    }
  }, [cartCoupon])
  const onApplyCoupon = async () => {
    if (doUseCoupon()) {
      await setCartCoupon(activeCouponCode)
    }
  }
  if (!doUseCoupon()) {
    return <div />
  }
  const couponStatus: COUPONVALIDATION = getCouponStatus()
  return (
    <div className={`${css.body} ${MERCE_CLASS_NAMES.CART_PROMO_FIELD}`}>
      <div className={`${css.item} ${THEME_BUTTON_TYPES.PRIMARY}`}>
        <div className={css.couponWrapper}>
          <label htmlFor="promoCode" className="is-hidden">
            :
          </label>
          <input
            id="promoCode"
            className={css.couponInput}
            onChange={onCouponChange}
            placeholder="Promo Code"
            value={activeCouponCode}
          />
          <button className={`${css.couponButton} core`} onClick={onApplyCoupon}>
            Apply
          </button>
        </div>
      </div>
      {couponStatus ? (
        <div className={`${css.couponError} ${couponStatus === COUPONVALIDATION.VALID ? css.valid : ''}`}>
          <p style={{ marginTop: '5px' }}>{getCouponStatus()}</p>
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  )
}

export default CouponField
