import { COUPONVALIDATION, ICouponValidation } from '../../Coupon/Coupon'
import { ICoupon } from '../../../PDP'
import { ITransport, CMS_API_CALLS } from '../../Services/API/CMS'
import { IApiCouponResponse, ICartItem } from '../../../Cart'
import { ICouponValidationArguments } from '../../../Cart/State/Context/CartContext'

const isCouponDateValid = (coupon: ICoupon): boolean => {
  const now: number = Date.now()
  const start: number = Date.parse(String(coupon.startDate))
  const end: number = Date.parse(String(coupon.endDate))
  return now > start && now < end
}

//  const response: IApiCouponResponse = await CMS_API_CALLS.COUPON.getCoupon(transport, params)
const isCouponValid = (
  coupon: ICoupon,
  subTotal: number,
  notMapSubTotal: number | undefined,
  isCouponValidHook?: (data: ICouponValidationArguments) => boolean,
  cartItems?: ICartItem[],
  setCouponTotal?: (price: number) => void,
): ICouponValidation => {
  const couponStatus = COUPONVALIDATION.INVALID
  if (coupon) {
    const oneUseOnlyValidation = coupon.oneUseOnly ? !coupon.usesCount : true
    if (oneUseOnlyValidation) {
      if (subTotal > Number(coupon.rules[0].minSpend)) {
        if (!isCouponDateValid(coupon)) {
          return {
            status: COUPONVALIDATION.INACTIVE,
          }
        }
        if (notMapSubTotal !== undefined && notMapSubTotal <= 0) {
          return {
            status: COUPONVALIDATION.NONEMAP,
            coupon,
          }
        }
        if (isCouponValidHook) {
          if (!isCouponValidHook({ coupon, cartItems, setCouponTotal })) {
            return {
              status: COUPONVALIDATION.INVALID,
            }
          }
        }
        return {
          status: COUPONVALIDATION.VALID,
          coupon,
        }
      } else {
        return {
          status: COUPONVALIDATION.MINSPEND,
        }
      }
    } else {
      return {
        status: COUPONVALIDATION.USED,
      }
    }
  }
  return { status: couponStatus }
}

const isCouponCodeValid = async (
  code: string,
  subTotal: number,
  notMapSubTotal: number | undefined,
  transport: ITransport,
  isCouponValidHook?: (data: ICouponValidationArguments) => boolean,
  cartItems?: ICartItem[],
  setCouponPrice?: (total: number) => void,
): Promise<ICouponValidation> => {
  const response: IApiCouponResponse = await CMS_API_CALLS.COUPON.getCouponByCode(transport, { code })
  if (!response.error && response.data) {
    return isCouponValid(
      response.data,
      subTotal,
      notMapSubTotal,
      isCouponValidHook || undefined,
      cartItems || undefined,
      setCouponPrice || undefined,
    )
  }
  return {
    status: COUPONVALIDATION.INVALID,
  }
}

const calculateCouponDiscount = (
  coupon: ICoupon,
  subTotal: number,
  notMapSubTotal: number | undefined,
  isCouponValidHook?: (data: ICouponValidationArguments) => boolean,
  cartItems?: ICartItem[],
  couponTotal?: any,
): number => {
  const discountType: string = coupon.rules[0].discountType
  let calculatedCouponPrice: number = 0
  const validCoupon: ICouponValidation = isCouponValid(
    coupon,
    subTotal,
    notMapSubTotal,
    isCouponValidHook || undefined,
    cartItems || undefined,
    couponTotal || undefined,
  ) as ICouponValidation
  if (validCoupon.status === COUPONVALIDATION.VALID) {
    switch (discountType) {
      case 'Percent Off':
        if (notMapSubTotal !== undefined) {
          calculatedCouponPrice = Number((-notMapSubTotal * (coupon.rules[0].discount / 100)).toFixed(2))
        } else {
          calculatedCouponPrice = Number((-subTotal * (coupon.rules[0].discount / 100)).toFixed(2))
        }
        break
      case 'Amount Off':
        if (notMapSubTotal !== undefined && notMapSubTotal <= 0) {
          break
        }
        calculatedCouponPrice = -coupon.rules[0].discount
        break
      default:
        break
    }
  }
  return calculatedCouponPrice
}

export { isCouponCodeValid, calculateCouponDiscount, isCouponValid }
