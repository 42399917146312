import { IAuthPaymentResponse } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Checkout'
import { ITransport } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'

const voidPaymentTransaction = async (transport: ITransport, transactionId: string): Promise<IAuthPaymentResponse> => {
  try {
    const response = await transport.post('/handle-void-payment', { transactionId })
    return { data: response.data }
  } catch (e) {
    return {
      data: null,
      error: e.response?.data || null,
    }
  }
}

export default {
  voidPaymentTransaction,
}
