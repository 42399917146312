import { IBlogCategory } from '../../../../../pages/blog/category'

export interface ITransport {
  get: any
  put: any
  post: any
  delete: any
}

export interface IBlogCategorySummary {
  category: IBlogCategory
  subcategories: IBlogCategory[]
}

export enum VISIBILITY {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}
export interface ICategoryBlogPostsApiParams {
  identifier: string
  visibility?: VISIBILITY
  cacheKey: string
  _limit?: number // add limit parameter to allow fetch some records
}

const ENDPOINTS = {
  CATEGORY_BLOG_POSTS_ENDPOINT: `categories/blog/posts`,
  BLOG_SUMMARY: `blog/summary`,
}

interface IQueryVars {
  [x: string]: any
}

function appendQueryString(url: string, queryVars: IQueryVars) {
  const firstSeperator: string = url.indexOf('?') === -1 ? '?' : '&'
  const queryStringParts: string[] = []
  for (const key in queryVars) {
    if (queryVars[key]) {
      if (Array.isArray(queryVars[key])) {
        const array: any[] = queryVars[key]
        for (const item of array) {
          queryStringParts.push(key + '=' + item)
        }
      } else {
        queryStringParts.push(key + '=' + queryVars[key])
      }
    }
  }
  const queryString = queryStringParts.join('&')
  return url + firstSeperator + queryString
}

// ----------------------------------------------  blog category blog posts ---------------------------------------------------
const getCategoryBlogPosts = async (
  transport: ITransport,
  params: ICategoryBlogPostsApiParams,
): Promise<{
  data: IBlogCategorySummary | null
  error: string
}> => {
  try {
    const finalEndpoint: string = appendQueryString(`/${ENDPOINTS.CATEGORY_BLOG_POSTS_ENDPOINT}`, params)
    const response = await transport.get(finalEndpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

// -------------------------------------------------- blog summary -----------------------------------------------------------------
const getBlogSummary = async (transport: ITransport, cacheKey: string) => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.BLOG_SUMMARY}?limit=1000`
    const response = await transport.get(finalEndpoint, {
      headers: { cacheKey },
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getBlogSummary,
  getCategoryBlogPosts,
}
