import { ITransport } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import {
  ILoginResponseInterface,
  IUserData,
  IAccountResponse,
  IForgotPasswordResponse,
} from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Account'

const ENDPOINTS = {
  LOGIN: `auth/local`,
  REGISTER: `auth/local/register`,
  FORGOT_PASSWORD: `auth/forgot-password`,
  RESET_PASSWORD: `auth/reset-password`,
  GET_ROLES: `users-permissions/roles`,
  GET_USER: 'users',
  UPDATE_USER: 'users',
}

export interface ILoginParams {
  identifier: string
  password: string
  ipAddress?: string
}

// ----------------------------------------------  blog category blog posts ---------------------------------------------------
const login = async (
  cmsTransport: ITransport,
  params: {
    identifier: string
    password: string
    ipAddress?: string
  },
): Promise<ILoginResponseInterface> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.LOGIN}`
    const response = await cmsTransport.post(finalEndpoint, { ...params })
    const { user, jwt } = response.data
    const account: any = {
      token: jwt,
      user: {
        ...user,
      },
    }
    return { error: '', data: account }
  } catch (e) {
    return {
      error: 'Identifier or password invalid.',
      data: null,
    }
  }
}

const register = async (transport: ITransport, params: Partial<IUserData>): Promise<IAccountResponse> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.REGISTER}`
    const response = await transport.post(finalEndpoint, params)
    const { jwt } = response.data
    const account: any = {
      token: jwt,
      ...response.data,
    }
    return { error: '', data: account }
  } catch (e) {
    return {
      error: e.response?.data?.message || 'Unable to register user',
      data: null,
    }
  }
}

const forgotPassword = async (transport: ITransport, params: Partial<IUserData>): Promise<IForgotPasswordResponse> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.FORGOT_PASSWORD}`
    const response = await transport.post(finalEndpoint, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: e.response?.data?.message || 'Error while handling forgot password',
      data: null,
    }
  }
}

const resetPassword = async (transport: ITransport, params: Partial<IUserData>): Promise<ILoginResponseInterface> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.RESET_PASSWORD}`
    const response = await transport.post(finalEndpoint, params)
    const { user, jwt } = response.data
    const account: any = {
      token: jwt,
      user: {
        ...user,
      },
    }
    return { error: '', data: account }
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: e.response?.data?.message || 'Error while resetting password',
      data: null,
    }
  }
}

const getRoles = async (transport: ITransport): Promise<any> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.GET_ROLES}`
    const response = await transport.get(finalEndpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: e.response?.data?.message || 'Unable to retrieve roles',
      data: null,
    }
  }
}

const getUser = async (transport: ITransport, params: Partial<IUserData>): Promise<IAccountResponse> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.GET_USER}/${params.id ? params.id : params._id}`
    const response = await transport.get(finalEndpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: e.response?.data?.message || 'Unable to retrieve user',
      data: null,
    }
  }
}

const updateUser = async (transport: ITransport, params: Partial<IUserData>): Promise<IAccountResponse> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.UPDATE_USER}/${params.id ? params.id : params._id}`
    const response = await transport.put(finalEndpoint, params)
    return { error: '', data: response.data }
  } catch (e) {
    return { error: e.response?.data?.message || 'Unable to update user', data: null }
  }
}

export default {
  login,
  getRoles,
  register,
  forgotPassword,
  getUser,
  updateUser,
  resetPassword,
}
