import * as React from 'react'
import { IProduct } from '../../PDP'
import { MERCE_CLASS_NAMES } from '../../interfaces'
import css from './CartItems.scss'
import Item from './Item/Item'
import { ICartEventProps, ICartItem, ICartItemProps } from '..'

export interface IProps {
  items: ICartItem[]
  events: ICartEventProps
  renderShipsBySection?: (product: IProduct, quantity?: number) => JSX.Element
  renderCartItem?: (item: ICartItem, props?: ICartItemProps) => JSX.Element
  defaultThumbnail?: string
  clearCartOption?: boolean
  sectionVisibility?: {
    [key: string]: boolean
  }
}

export interface IState {
  selectionIDs: string[]
}

export default class ICartItems extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      selectionIDs: [],
    }
  }
  public renderCartBody = (): JSX.Element[] | JSX.Element => {
    const { items } = this.props
    if (!items || items.length === 0) {
      return <p>There are no items in your cart...</p>
    }
    const itemProps = {
      events: this.props.events,
      renderShipsBySection: this.props.renderShipsBySection,
      defaultThumbnail: this.props.defaultThumbnail,
      onSelectionHandler: this.onSelectionHandler,
      selectionIDs: this.state.selectionIDs,
      allowBulkSelection: !!this.props.events?.onClearSelection,
    }
    return items.map((item: ICartItem, index: number) => {
      if (this.props.renderCartItem !== undefined) {
        return <div key={index}>{this.props.renderCartItem(item, itemProps)}</div>
      }
      return (
        <Item
          item={item}
          key={index}
          {...itemProps}
        />
      )
    })
  }

  public onSelectionHandler = (status: boolean, productID: string) => {
    let { selectionIDs } = this.state
    if (status) {
      selectionIDs.push(productID)
    } else {
      selectionIDs = selectionIDs.filter(item => item !== productID)
    }
    this.setState({ selectionIDs })
  }

  public onClearSelection = () => {
    const { events } = this.props
    const { selectionIDs } = this.state
    if (events.onClearSelection) {
      events.onClearSelection(selectionIDs)
      this.setState({ selectionIDs: [] })
    }
  }

  public getSectionVisibility = (sectionName: string): boolean => {
    const { sectionVisibility } = this.props
    if (sectionVisibility?.hasOwnProperty(sectionName)) {
      return sectionVisibility[sectionName]
    }
    return true
  }

  public render(): JSX.Element {
    const { selectionIDs } = this.state
    return (
      <div className={`${css.cart} ${MERCE_CLASS_NAMES.CART_PRODUCT_SUMMARY}`}>
        {this.getSectionVisibility('cartHeader') && (
          <div className={css.title}>
            <span className={css.message}>Your cart</span>
            <div className={css.cartActionContainer}>
              {this.getSectionVisibility('continueShopping') ? (
                  <span className={css.continueShopping} onClick={this.props.events.onContinueShopping}>
                    <div className={`${css.textButton} ${css.continueShopping}`}>Continue Shopping</div>
                  </span>
                ) : null
              }
              <div className={css.clearCart}>
                {selectionIDs.length > 0 && this.props.events.onClearSelection ? (
                  <span className={css.clearCart && css.removeCart} onClick={this.onClearSelection}>
                    <div className={`${css.textButton}`}>
                      <i className={`fa fa-trash-alt ${css.deleteIcon}`}></i>
                      Remove selection
                    </div>
                  </span>
                ) : null}
                {this.props.clearCartOption ? (
                  <span className={css.clearCart} onClick={this.props.events.onClearCart}>
                    <div className={`${css.textButton}`}>
                      <i className={`fa fa-trash-alt ${css.deleteIcon}`}></i>
                      Clear Cart
                    </div>
                  </span>
                ) : null}
              </div>
            </div>
            <div className={css.cartActionContainerMobile}>
              {selectionIDs.length > 0 && this.props.events.onClearSelection ? (
                <span className={css.clearCart && css.removeCart} onClick={this.onClearSelection}>
                  <div className={`${css.textButton}`}>
                    <i className={`fa fa-trash-alt ${css.deleteIcon}`}></i>
                    Remove selection
                  </div>
                </span>
              ) : null}
            </div>
          </div>
        )}
        {this.renderCartBody()}
      </div>
    )
  }
}
