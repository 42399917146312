import * as React from 'react'
import css from './ImageLoader.scss'

export interface IProps {
  src: string
  alt: string
  className?: string
  responsive?: boolean
  border?: boolean
  center?: boolean
}
interface IState {
  error: boolean
}
/**
 * Utility used to help fallback images if the url is broken
 * @param className string: you can proxy through a className
 * @param responsive boolean: make it responsive, false by default
 */
export default class ImageLoader extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { error: false }
  }
  public onError = (): void => {
    this.setState({ error: true })
  }
  public generateClasses = (): string => {
    const { className, responsive, border, center } = this.props
    const classes: string[] = []
    if (className) {
      classes.push(className)
    }
    if (responsive) {
      classes.push(css.responsive)
    }
    if (border) {
      classes.push(css.border)
    }
    if (center) {
      classes.push(css.center)
    }
    return classes.join(' ')
  }
  // TODO: clean this up and move into classes
  public render() {
    const { error } = this.state
    if (error) {
      return (
        <div className="img-thumbnail" style={{ height: '100%' }}>
          <div
            style={{
              background: '#eee',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            Image Not Availables
          </div>
        </div>
      )
    }
    const { src, alt } = this.props
    return <img src={src} alt={alt} onError={this.onError} className={this.generateClasses()} />
  }
}
