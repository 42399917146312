import * as React from 'react'
import { ICartItem } from '..'

export interface ICartItemCarouselProps {
  title: string
  items: ICartItem[]
}

export default class CartItemCarousel extends React.Component<ICartItemCarouselProps, any> {
  public render() {
    return (
      <div>
        <h1>{this.props.title}</h1>
        <p>Item thumbnails here</p>
      </div>
    )
  }
}
