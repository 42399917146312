import Axios from 'axios'
import getConfig from 'next/config'
import { GERS_SERVICE } from '../../../../../settings/variables'
const { publicRuntimeConfig } = getConfig()
export const CMS_API = publicRuntimeConfig.CMS_API || 'http://localhost:1337'
export const CACHE_ENDPOINT = publicRuntimeConfig.CACHE_ENDPOINT || ''

export interface IGersStoreResponse {
  error: string
  data: IGersStoreSummary | null
}

export interface IGersStoreSummary {
  zipCode: string
  storeCode: string
  warehouseCode: string
  errorCode: string
  errorMessage: string
}

export interface IStoreCodes {
  storeCode: string
  warehouseCode: string
}

const ENDPOINTS = {
  LOCATE: 'store/locate',
}

const getClosestStoreInfo = async (storeOrZipCode: string): Promise<IGersStoreResponse> => {
  try {
    const endpoint = `${GERS_SERVICE}/${ENDPOINTS.LOCATE}`
    const response = await Axios.post(endpoint, {
      storeOrZipCode,
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default { getClosestStoreInfo }
