import * as React from 'react'
import { find } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import { MERCE_CLASS_NAMES } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/interfaces'
import { IProductImageType } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import bootstrap from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Layout/Layout.module.scss'
import css from './VerticalCarousel.module.scss'
import Chevron from '../Chevron/Chevron'
import GestureRecognizer from '../../../../Common/GestureRecognizer/GestureRecognizer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import Thumbnail from '../../Common/Thumbnail'
import { isEqual } from 'lodash'
import LazyImage from '../../../Common/Image/LazyImage'

export interface ISliderItem {
  url: string
  alt: string
  type: IProductImageType
  thumbnail?: string
  sequenceNumber?: number
}
interface IProps {
  sliderData: ISliderItem[]
  defaultImage?: string
  horizontal?: boolean
  defaultImageWidth?: string
  isStoreLocation?: boolean
  mainImageWidth?: string
}
interface IState {
  thumbnailStartIndex: number
  mainImgIndex: number
  sliderItemArray: ISliderItem[]
  hasNavigation: boolean
}

interface IDuplicateSortItem {
  sort: number
  slides: ISliderItem[]
}

/**
 * We ran into issues where sequenceNumbers could be the same and it sorts differently on client vs server
 * So we have to account for that in this method
 * @param slides
 */
const sortSliderItems = (slides: ISliderItem[] | null | undefined): ISliderItem[] => {
  if (!slides) {
    return []
  }
  let hasSequenceNumber: boolean = true
  //not all image sets will have a sequence number since this field was just introduced
  for (const slide of slides) {
    if (slide.sequenceNumber === undefined) {
      hasSequenceNumber = false
    }
  }
  if (!hasSequenceNumber) {
    return slides
  }
  const sequenceNumberGroups: IDuplicateSortItem[] = []
  for (const slide of slides) {
    const slideSequenceNumber = Number(slide.sequenceNumber)
    const numberExists = find(sequenceNumberGroups, (n: IDuplicateSortItem) => {
      return n.sort == slideSequenceNumber
    })
    //determine if the sequenceNumber has been used already
    if (!numberExists) {
      sequenceNumberGroups.push({
        sort: slideSequenceNumber,
        slides: [slide],
      })
    } else {
      numberExists.slides.push(slide)
    }
  }
  const finalSortedItemList = sequenceNumberGroups.sort((a: IDuplicateSortItem, b: IDuplicateSortItem) => {
    const valueA: number = a.sort ?? 0
    const valueB: number = b.sort ?? 0
    return valueA > valueB ? 1 : -1
  })
  const sortedListes: ISliderItem[] = []
  for (const item of finalSortedItemList) {
    for (const slide of item.slides) {
      sortedListes.push(slide)
    }
  }
  return sortedListes
}

class VerticalCarousel extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const sortedItems: ISliderItem[] = sortSliderItems(this.props.sliderData || [])
    this.state = {
      thumbnailStartIndex: 0,
      mainImgIndex: 0,
      sliderItemArray: sortedItems || [],
      hasNavigation: sortedItems && sortedItems.length > 5,
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (!isEqual(this.props, prevProps)) {
      this.setState({
        thumbnailStartIndex: 0,
        mainImgIndex: 0,
      })
    }
    const sortedItems: ISliderItem[] = sortSliderItems(this.props.sliderData || [])
    if (!isEqual(sortedItems, this.state.sliderItemArray)) {
      this.setState({
        sliderItemArray: sortedItems,
        hasNavigation: sortedItems && sortedItems.length > 5,
      })
    }
  }

  private onSwipeLeft = () => {
    this.onClickUp()
  }

  private onSwipeRight = () => {
    this.onClickDown()
  }

  public setMainImg = (index: number, isVideo: boolean) => () => {
    if (isVideo) {
      // workaround to make the video to refresh
      this.setState({
        mainImgIndex: -1,
      })
      setTimeout(() => {
        this.setState({
          mainImgIndex: index,
        })
      }, 1)
    } else {
      this.setState({
        mainImgIndex: index,
      })
    }
  }

  public onClickUp = () => {
    // mobile controls
    const { sliderItemArray } = this.state
    const index = this.state.mainImgIndex === 0 ? sliderItemArray.length - 1 : this.state.mainImgIndex - 1
    let thumbnailStartIndex =
      index < this.state.thumbnailStartIndex && this.state.thumbnailStartIndex > 0
        ? this.state.thumbnailStartIndex - 1
        : this.state.thumbnailStartIndex
    thumbnailStartIndex = index === sliderItemArray.length - 1 ? sliderItemArray.length - 5 : thumbnailStartIndex

    const isVideo = sliderItemArray[index].type === IProductImageType.VIDEO
    if (isVideo) {
      // workaround to make the video to refresh
      this.setState({
        mainImgIndex: -1,
      })
      setTimeout(() => {
        this.setState({
          mainImgIndex: index,
          thumbnailStartIndex,
        })
      }, 1)
    } else {
      this.setState({
        mainImgIndex: index,
        thumbnailStartIndex,
      })
    }
  }
  public onClickDown = () => {
    // mobile controls
    const { sliderItemArray, thumbnailStartIndex } = this.state
    const index = this.state.mainImgIndex === sliderItemArray.length - 1 ? 0 : this.state.mainImgIndex + 1
    let thumbnailStartIndexLocal =
      index === thumbnailStartIndex + 5 && thumbnailStartIndex < sliderItemArray.length + 1
        ? thumbnailStartIndex + 1
        : thumbnailStartIndex
    thumbnailStartIndexLocal = index === 0 ? 0 : thumbnailStartIndexLocal

    const isVideo = sliderItemArray[index].type === IProductImageType.VIDEO
    if (isVideo) {
      // workaround to make the video to refresh
      this.setState({
        mainImgIndex: -1,
      })
      setTimeout(() => {
        this.setState({
          mainImgIndex: index,
          thumbnailStartIndex: thumbnailStartIndexLocal,
        })
      }, 1)
    } else {
      this.setState({
        mainImgIndex: index,
        thumbnailStartIndex: thumbnailStartIndexLocal,
      })
    }
  }

  // ------------------------------------------------------------ RENDER ---------------------------------------------------------------------------------------------
  public renderThumbnails() {
    if (this.state.sliderItemArray && this.state.sliderItemArray.length > 0) {
      return this.state.sliderItemArray.map((imgSrc: ISliderItem, index: number) => {
        const inactiveClass = index !== this.state.mainImgIndex ? css.inactiveImgContainer : ''
        const isVideo = imgSrc.type === IProductImageType.VIDEO
        const placeholder = this.props.defaultImage || 'https://via.placeholder.com/800x800'
        const defaultThumbnail =
          (isVideo && imgSrc.thumbnail && imgSrc.thumbnail.length <= 0) || !imgSrc.thumbnail
            ? placeholder
            : imgSrc.thumbnail
        const isHidden = index < this.state.thumbnailStartIndex || index >= this.state.thumbnailStartIndex + 5
        const hide = isHidden ? css.hidden : ''
        return (
          <div
            key={index}
            className={`${
              this.props.horizontal
                ? `${css.carouselImgContainer} ${css.horizontalImgContainer}`
                : css.carouselImgContainer
            } ${inactiveClass} ${hide}`}
            onClick={this.setMainImg(index, isVideo)}
            role="presentation"
          >
            <div className={`${inactiveClass} ${css.thumbnailContainer}`}>
              {isVideo && <FontAwesomeIcon icon={faPlayCircle} className={css.playIcon} />}
              {isVideo ? (
                <div
                  className={`${css.imageThumbnail} fa`}
                  role="img"
                  aria-label={imgSrc.alt}
                  style={{
                    backgroundImage: `url("${isVideo ? defaultThumbnail : imgSrc.url}")`,
                  }}
                />
              ) : (
                <LazyImage src={imgSrc.url} alt={imgSrc.alt} width={98} height={98} objectFit="contain" />
              )}
            </div>
          </div>
        )
      })
    } else {
      return <div />
    }
  }

  public renderContentBlock = (item: ISliderItem) => {
    const containerClasses = `${css.mainImgBlock} ${css.fade} ${this.props.isStoreLocation ? css.storelocation : ''}`
    if (item) {
      if (item.type === IProductImageType.IMAGE) {
        return (
          <Thumbnail
            imgAltText={item.alt}
            imgUrl={item.url}
            containerClass={
              this.props.mainImageWidth ? `${css.containerClasses} ${this.props.mainImageWidth}` : css.containerClasses
            }
          />
        )
      } else if (item.type === IProductImageType.VIDEO) {
        return (
          <div className={css.videoContainer}>
            <video width="100%" height="100%" controls={true} preload="metadata">
              <source src={item.url} />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      }
    }
    return (
      <Thumbnail
        imgAltText="No images present"
        imgUrl={this.props.defaultImage || 'https://via.placeholder.com/800x400'}
        containerClass={containerClasses}
      />
    )
  }

  public render() {
    return (
      <GestureRecognizer onSwipeLeft={this.onSwipeLeft} onSwipeRight={this.onSwipeRight}>
        <div className={this.props.horizontal ? css.horizontalCarousel : css.verticalCarousel}>
          <div className={this.props.horizontal ? `${css.carousel} ${css.horizontal}` : css.carousel}>
            <div className={`${bootstrap['hidden-sm']} ${bootstrap['hidden-xs']}`}>
              {this.state.hasNavigation && (
                <Chevron side={this.props.horizontal ? 'left' : 'up'} onClick={this.onClickUp} />
              )}
              <div
                className={`${MERCE_CLASS_NAMES.PDP_PRODUCT_PHOTO_CAROUSEL} ${
                  this.props.horizontal ? css.horizontalThumbnails : null
                }`}
              >
                {this.renderThumbnails()}
              </div>
              {this.state.hasNavigation && (
                <Chevron side={this.props.horizontal ? 'right' : 'down'} onClick={this.onClickDown} />
              )}
            </div>
          </div>
          <div
            className={`${MERCE_CLASS_NAMES.PDP_PRODUCT_PHOTO_MAIN} ${css.mainImageContainer} ${
              this.props.horizontal ? css.horizontalImageContainer : ''
            }`}
          >
            {/* remove next div for div with image background */}
            <div>
              <div className={`${bootstrap['visible-sm']} ${bootstrap['visible-xs']} ${css.centerNavigation}`}>
                <Chevron side="left" onClick={this.onClickUp} />
              </div>
            </div>
            {this.renderContentBlock(this.state.sliderItemArray[this.state.mainImgIndex])}
            {/* remove next div for div with image background */}
            <div>
              <div className={`${bootstrap['visible-sm']} ${bootstrap['visible-xs']} ${css.centerNavigation}`}>
                <Chevron side="right" onClick={this.onClickDown} />
              </div>
            </div>
          </div>
        </div>
      </GestureRecognizer>
    )
  }
}
export default VerticalCarousel
