import { CYLINDO_VIEWER_ID } from '../../../../../settings/variables'
import { IPDPProduct } from '../PDP'
import { ICylindoViewerInstance, isImage360Viewer } from './CylindoViewer'

/**
 * When navigating from an IMAGE360 product to a not IMAGE360 product we need to destroy the cylindo viewer instance,
 * because if we move back to the IMAGE360 product the update Cylindo viewer method does not work and the Cylindo Viewer is not displayed.
 *
 * @param currentProduct
 * @param targetProduct
 */
const destroyCylindoViewerInstance = (currentProduct: IPDPProduct, targetProduct: IPDPProduct) => {
  if (isImage360Viewer(currentProduct) && !isImage360Viewer(targetProduct)) {
    const cylindoProductId = currentProduct?.variants?.[0]?.cylindoProductId || currentProduct?.cylindoProductId
    const viewerInstance: ICylindoViewerInstance = cylindo.viewer.getInstances()[
      `${cylindoProductId}_${CYLINDO_VIEWER_ID}`
    ]
    if (viewerInstance) {
      cylindo.viewer.getInstances()[`${cylindoProductId}_${CYLINDO_VIEWER_ID}`] = null
    }
  }
}

export { destroyCylindoViewerInstance }
