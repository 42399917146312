import { ICoupon, IProduct } from "./Product";

export interface IVendorShippingOptions {
  title: string
  deliveryDate: string
}

export interface IVendorShipping {
  vendorId: string
  items: string[]
  details: IVendorShippingOptions[]
}

export interface ICartItem {
  product: IProduct
  metaData?: { [x: string]: any }
  quantity: number
  note?: string
  nullProduct?: boolean
}

export interface ICartPrice {
  subTotal: number
  notMapSubTotal?: number
  totalFromCoupon?: number
  shipping: number
  coupon: number
  tax: number
  rate: number
  refundAmount?: number
  couponCode?: string
}

export interface ICartShippingRule {
  id: string
  _id: string
  title: string
  enabled: boolean
  configuration: any
}

export interface ICart {
  items: ICartItem[]
  promoCode: string
  userId: string
  anonId: string
  coupon?: string
}

export enum CART_COLLECTION_TYPE {
  SAVE_FOR_LATER = 'SAVE_FOR_LATER',
  FAVORITE = 'FAVORITE',
}

export interface ICartCollection {
  id: string
  _id: string
  items: ICartItem[]
  email: string
  type: CART_COLLECTION_TYPE
}

export interface ICartSummary {
  count: number
  items: ICartItem[]
  status?: string
  coupon?: ICoupon
}

export interface IIdentity {
  anonId?: string
  userId?: string
}
