import { AxiosResponse } from 'axios'
import { IncomingMessage } from 'http'
import appendQueryString from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'
import CACHE_CONTSTANT from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/cache/constants'
import {
  IUnbxdProduct,
  IUnbxdProductResponse,
} from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import {
  IAttribute,
  IProduct,
  IProductGroup,
  IVendor,
} from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import { isImage360Viewer } from '../../components/Tenant/Layout/PDP/CylindoViewer/CylindoViewer'
import { IAttributeSwatch, ISwatch } from '../../components/Tenant/Layout/PDP/Swatches/Swatches'
import { CMS_API_CALLS } from '../../components/Tenant/Services/CMS'
import { IStoreCodes } from '../../components/Tenant/Services/GERS/Pricing/storelocator'
import { isCustomProduct } from '../../components/Utils/customProductUtils'
import { getStoreCodes } from '../../components/Utils/getStoreCodeFromCookie'
import { IMorUnbxdProduct, IRelatedType } from '../../components/Utils/unbxdUtils'
import getDefaultCmsTransport from '../../services/api/cmsInterceptor'
import { merchantVisibility, storeAvailability } from '../../settings/variables'
import { getUnbxdCatalog, getUnbxdProduct } from '../../utils/unbxd/unbxdApiCalls'
import { adaptMorProductResponse } from '../../utils/unbxd/unbxdHelpers'
export interface IProductEnabledMetadata {
  sku: string
  enabled: boolean
}
/**
 * This method could be use to get a related item from another product, and usually a product has a list of
 * related items so that's why it can receive a list of skus. If that is the case we only need to get the related item
 * with Merch Visibility = CATALOG, SEARCH.
 *
 * @param sku one sku or a list of skus
 * @returns returns a single element array with the corresponding product.
 */
export async function getProductsBySku(sku: string | string[]) {
  let products = null
  let unbxdProductResponse: AxiosResponse | null = null
  let targetSku = sku as string
  if (Array.isArray(sku)) {
    let url: string = `?filter=(${sku.map(s => encodeURIComponent(`sku:${s}`)).join('%20OR%20')})`
    url = appendQueryString(url, { filter: merchantVisibility })

    unbxdProductResponse = await getUnbxdProduct(url)
    products = unbxdProductResponse?.data?.response?.products
    targetSku = products?.[0]?.variants?.[0]?.sku
  } else {
    const url: string = '?filter=' + encodeURIComponent(`sku:${sku}`)

    unbxdProductResponse = await getUnbxdProduct(url)
    products = unbxdProductResponse?.data?.response?.products
  }

  // Filter the resulted products to match the sku as unbxd responses are not 100% accurate.
  // In case of root product merge with variant info.
  if (products?.length === 1 && products[0].uniqueId === products[0]._root_) {
    products = [
      {
        ...products[0],
        ...products[0].variants.find((e: { sku: string }) => e?.sku?.toLowerCase() === targetSku?.toLowerCase()),
      },
    ]
  } else {
    products = products?.filter((e: { sku: string }) => e?.sku?.toLowerCase() === targetSku?.toLowerCase()) || []
  }

  // Update unbxdProductResponse after filters
  unbxdProductResponse.data.response.products = products

  return unbxdProductResponse
}

export async function getSkusInfo(unbxdProductResponse: AxiosResponse, attrs: IAttribute[]) {
  let setSkusInfo: IUnbxdProduct[] = []
  const skus: string[] = unbxdProductResponse.data.response?.products?.[0]?.setSkus || []

  if (skus?.length > 0) {
    let url: string = `?filter=(${skus.map(sku => encodeURIComponent(`sku:${sku}`)).join('%20OR%20')})`
    url = appendQueryString(url, { variants: 'false' })
    const productResponse: AxiosResponse = await getUnbxdProduct(url)
    const adaptedResponse: IUnbxdProductResponse = adaptMorProductResponse(productResponse.data.response, attrs, [])
    setSkusInfo = adaptedResponse.products
  }
  return setSkusInfo
}

export async function getProductMetadata() {
  const query: string = `
  {
    vendors {
      outOfStockThreshold
      name
      shippingrules
    }
    productattributes{
      code
      title
      displayType {
        code
      }
      group {
        label
        code
      }
      type {
        code
      }
    }
  }
  `
  return await getDefaultCmsTransport(true).post('/graphql', {
    query,
    cacheKey: `${CACHE_CONTSTANT.FRONTEND.PIM.ROOT}-product-metadata`,
  })
}

export async function getProductsEnabledMetadata(skus: string[]) {
  const query: string = `
  {
    products (where: { sku_in: ${JSON.stringify(skus)} }) {
      enabled
      sku
    }
  }
  `
  return await getDefaultCmsTransport().post('/graphql', {
    query,
  })
}

const hasRelatedItems = (targetProduct: IMorUnbxdProduct) => {
  return (
    (targetProduct.variants?.[0].RELATED_TYPE === IRelatedType.CUSTOMSKU ||
      targetProduct.variants?.[0].RELATED_TYPE === IRelatedType.INVSKU) &&
    targetProduct.variants[0].RELATED_ITM_CD_LIST?.length
  )
}

const compareSequenceNumber = (s1: ISwatch, s2: ISwatch) => {
  if (s1.sequence < s2.sequence) {
    return -1
  }
  if (s1.sequence > s2.sequence) {
    return 1
  }
  return 0
}

const getProductGroupData = async (
  targetProduct: IUnbxdProduct,
  codes: IStoreCodes,
  attrs: IAttribute[],
  vendors: IVendor[],
) => {
  let groupQuery: string = ''
  groupQuery = appendQueryString(groupQuery, { p: `productgroup:"${targetProduct.productgroup}"` })
  groupQuery = appendQueryString(groupQuery, { V2: 'V2' })
  groupQuery = appendQueryString(groupQuery, { pagetype: 'boolean' })
  groupQuery = appendQueryString(groupQuery, { start: '0' })
  groupQuery = appendQueryString(groupQuery, { rows: '100' })
  groupQuery = appendQueryString(groupQuery, { variants: 'false' })
  groupQuery = appendQueryString(groupQuery, { facet: 'false' })
  if (!isCustomProduct(targetProduct as IMorUnbxdProduct)) {
    groupQuery = appendQueryString(groupQuery, { filter: merchantVisibility })
  }
  if (codes?.storeCode) {
    groupQuery = appendQueryString(groupQuery, { filter: `${storeAvailability}${codes.storeCode}` })
  }

  const productGroupResponse: any = await CMS_API_CALLS.PRODUCT_GROUP.getProductGroup(getDefaultCmsTransport(true), {
    id: targetProduct.productgroup as any,
  })

  if (productGroupResponse?.data?.metadata?.swatches) {
    const groupSwatches: IAttributeSwatch[] = JSON.parse(productGroupResponse.data.metadata.swatches)
    groupSwatches.forEach(sw => sw.swatches.sort(compareSequenceNumber))
    targetProduct.groupSwatches = groupSwatches
  }

  const productsInGroup: AxiosResponse = await getUnbxdCatalog(groupQuery)

  const adaptedProducts: IUnbxdProductResponse = adaptMorProductResponse(productsInGroup.data.response, attrs, vendors)
  const group: IProductGroup = {
    ...productGroupResponse.data,
    products: adaptedProducts.products as IProduct[],
  }

  return group
}

const getProductAndProductGroupBySku = async (sku: string | string[], req?: IncomingMessage) => {
  const codes = getStoreCodes(req)

  const productMetaDataResponse: AxiosResponse = await getProductMetadata()
  const attrs: IAttribute[] = productMetaDataResponse.data?.data?.productattributes
  const vendors: IVendor[] = productMetaDataResponse.data?.data?.vendors

  const unbxdProductResponse: AxiosResponse = await getProductsBySku(sku)
  const setSkusInfo = await getSkusInfo(unbxdProductResponse, attrs)

  const adaptedResponse: IUnbxdProductResponse = adaptMorProductResponse(
    unbxdProductResponse.data.response,
    attrs,
    vendors,
    setSkusInfo,
  )
  const targetProduct: IUnbxdProduct | null = adaptedResponse.products ? adaptedResponse.products?.[0] : null

  if (targetProduct?.productgroup) {
    targetProduct.productgroup = await getProductGroupData(targetProduct, codes, attrs, vendors)
  }

  return targetProduct
}

/**
 * Gets all the needed data to render a PDP. This includes:
 * Products data, product group data and related items data.
 *
 * @param sku from the current product to render.
 * @param req
 * @param isMainProduct boolean to check if it is a main product or a related product.
 * @returns the Main product to be rendered on PDP with the related product as a nested object, if any.
 */
export async function getProductProps(sku: string, req?: IncomingMessage, isMainProduct?: boolean) {
  const targetProduct = await getProductAndProductGroupBySku(sku, req)

  if (
    targetProduct &&
    targetProduct.variants?.length &&
    isImage360Viewer(targetProduct as IMorUnbxdProduct) &&
    hasRelatedItems(targetProduct as IMorUnbxdProduct) &&
    isMainProduct
  ) {
    // if it is the main product being loaded, we need to get the related product props.
    const currentVariant = targetProduct.variants[0] as IUnbxdProduct
    const relatedItem = await getProductAndProductGroupBySku(currentVariant.RELATED_ITM_CD_LIST, req)
    targetProduct.relatedItems = [relatedItem]
  }

  return {
    product: targetProduct,
  }
}
