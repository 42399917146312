import * as React from 'react'
import css from './QuantityCounter.scss'

export interface IProps {
  quantity: number
  onQuantityChange: (num: number) => void
}
export default class QuantityCounter extends React.Component<IProps, any> {
  public onPlus = () => {
    const { onQuantityChange } = this.props
    onQuantityChange(1)
  }
  public onMinus = () => {
    const { onQuantityChange } = this.props
    onQuantityChange(-1)
  }
  public render() {
    const { quantity } = this.props
    return (
      <div className={css.quantityWrapper}>
        <div className={css.quantityContainer}>
          <span onClick={this.onMinus} className={css.minus}>
            –
          </span>
          <span className={css.quantity}>{quantity}</span>
          <span onClick={this.onPlus} className={css.plus}>
            +
          </span>
        </div>
      </div>
    )
  }
}
