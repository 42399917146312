import * as React from 'react'
import {
  ICartItem,
  ICartPrice,
  IApiCartGenericRequestParameters,
  IApiGetCartResponse,
  IApiCartItemRequestParameters,
  IApiQuantityCartRequestParameters,
  IIdentity,
  ICalculateCartProps,
  IApiCouponResponse,
  ICartSummary,
} from '../..'
import { COUPONVALIDATION, ICouponValidation } from '../../../Common/Coupon/Coupon'
import { ICouponApiParams } from '../../../Common/Services/API/CMS/coupons'
import { ICoupon } from '../../../PDP'
import { IAddress, IAccount } from '../../../Account'
import { CMS_API_CALLS, ITransport } from '../../../Common/Services/API/CMS'
import getSmallestPrice from '../../../Common/Utils/cart/getSmallestPrice'
import {
  calculateCouponDiscount,
  isCouponCodeValid,
  isCouponValid,
} from '../../../Common/Utils/cart/calculateCouponDiscount'
import { has } from '../../../Common/Utils/lodash'

// ---------------------------------------------------- METHOD PARAMETERS --------------------------------------------

export interface ICartMethods {
  getItems: (params: IApiCartGenericRequestParameters) => Promise<IApiGetCartResponse>
  addItemToCart: (params: IApiCartItemRequestParameters) => Promise<IApiGetCartResponse>
  removeItemFromCart: (params: IApiCartItemRequestParameters) => Promise<IApiGetCartResponse>
  deleteItemFromCart: (params: IApiCartItemRequestParameters) => Promise<IApiGetCartResponse>
  updateItemQuantity: (params: IApiQuantityCartRequestParameters) => Promise<IApiGetCartResponse>
  mergeCart: (params: IApiCartGenericRequestParameters) => Promise<IApiGetCartResponse>
  clearCart: (params: IApiCartGenericRequestParameters) => Promise<IApiGetCartResponse>
  isCouponValid?: (params: ICouponApiParams) => Promise<IApiCouponResponse | ICouponValidation>
  getOrderSessionId: () => string
  copyCart?: (params: { [x: string]: string }) => Promise<IApiGetCartResponse>
  clearSelection?: any
}
// -----------------------------------------------------------------------CONTEXT-----------------------------------------------------------
export interface ICartContextProviderProps {
  getCartCountFromServer: () => Promise<number>
  methods: ICartMethods
  cart: ICartItem[]
  coupon: ICoupon | null
  error: string
  count: number
  getCartTotal: (props: ICalculateCartProps) => Promise<ICartPrice>
  setCartCoupon: (code: string) => void
  getCartCoupon: () => ICoupon | null
  getCouponStatus: () => COUPONVALIDATION
  cartPricing: any
  doUseCoupon: () => boolean
  setCouponTotal?: (cart: any, total: number) => void
  cartCoupon: ICoupon | undefined
  couponTotal: number | null
  setUpdateCoupon?: (val: boolean) => void
  setCartPricing?: (data: any) => void
}
const CartContext = React.createContext<ICartContextProviderProps | null>(null)

export interface ICartUpdatedResponse {
  items: ICartItem[]
  count: number
}

export interface ICouponValidationArguments {
  coupon: ICoupon
  [x: string]: any
}
export interface ICouponDiscountCalculationArguments {
  [x: string]: any
  coupon: ICoupon | null
  subTotal: number
}
// ------------------------------------------------------------------------ CLASS ------------------------------------------------------
interface IIncomingProps {
  getCartTotal: (items: ICartItem[], address?: any) => Promise<ICartPrice>
  eventHooksMethod?: (type: string, params?: ICartUpdatedResponse) => void
  preSetCartStateHook?: (items: ICartItem[]) => ICartItem[]
  preAddtoCartEventHook?: (cart: ICartItem[], item: { [x: string]: any }) => Promise<string>
  enableCoupon?: boolean
  transport: ITransport
  anonId: string
  account: IAccount | null
  initialCartCount?: number
  configuration?: {
    preventCartPopulationUrls?: string[]
    calculations?: {
      getSubTotal?: (items: ICartItem[]) => number
      getNotMapSubTotal?: (items: ICartItem[]) => number
      couponDiscountCalculationHook?: (data: ICouponDiscountCalculationArguments) => number
      getTaxes?: (items: ICartItem[], discount?: number, address?: any) => Promise<ICartPrice>
    }
  }
  isCouponValidHook?: (data: ICouponValidationArguments) => boolean
}

interface IState {
  cart: ICartItem[]
  coupon: ICoupon | null
  couponStatus: COUPONVALIDATION
  cartPricing: ICartPrice | null
  error: string
  anonId: string
  cartCoupon: ICoupon | undefined
  couponTotal: number | null
  setCouponTotal?: (total: number) => void
  updateCoupon?: boolean | undefined
}

class CartDataProvider extends React.Component<IIncomingProps, IState> {
  constructor(props: IIncomingProps) {
    super(props)
    this.state = {
      cart: [],
      couponStatus: COUPONVALIDATION.NONE,
      coupon: null,
      cartPricing: null,
      error: '',
      anonId: this.props.anonId,
      cartCoupon: undefined,
      couponTotal: null,
      updateCoupon: false,
    }
  }

  // TODO: Don't do this
  public componentDidMount = async () => {
    const preventLoadUrls: string[] | null = has(this.props, ['configuration', 'preventCartPopulationUrls'])
    if (preventLoadUrls !== null) {
      if (location && location.pathname) {
        if (preventLoadUrls.indexOf(location.pathname) !== -1) {
          return
        }
      }
    }
    if (!this.props.initialCartCount) {
      this.getItems({ populate: false })
    }
  }

  /**
   * The area where we actually merge the cart
   * @param next
   */
  public UNSAFE_componentWillReceiveProps(next: IIncomingProps) {
    if (this.state.anonId) {
      if (next.account && next.account.user) {
        // then we merge
        this.mergeCart({
          populate: false,
          identity: {
            userId: next.account.user._id,
            anonId: this.state.anonId,
          },
        })
      }
    }
  }

  // public componentDidUpdate() {
  //   if (this.state.anonId !== this.props.anonId) {
  //     this.setState({ anonId: this.props.anonId })
  //     this.getItems({ populate: false })
  //   }
  // }

  public getIdentity = (): IIdentity => {
    const { account } = this.props
    const userId: string = account && account.user ? account.user._id : ''
    const anonId: string = this.state.anonId
    if (userId) {
      return { userId }
    }
    return {
      anonId,
    }
  }

  public getOrderSessionId = (): string => {
    return btoa(this.state.anonId)
  }

  public setCartState = async (response: IApiGetCartResponse) => {
    if (!response.error && response.data && response.data.status !== 'error') {
      let incomingCartItems: ICartItem[] = response.data.items
      if (this.props.preSetCartStateHook) {
        incomingCartItems = this.props.preSetCartStateHook(incomingCartItems)
      }
      this.setState({
        cart: incomingCartItems,
        cartCoupon: response.data.coupon,
      })
    } else {
      this.setState({
        error: response.error,
      })
    }
  }

  // -------------------------------------------------------------------------COUPON LOGIC-------------------------------------------------------------------------------

  public doUseCoupon = () => {
    return this.props.enableCoupon !== undefined && this.props.enableCoupon === true
  }

  public getCartCoupon = () => {
    return this.state.coupon
  }

  public getCouponStatus = () => {
    return this.state.couponStatus
  }

  public setCouponTotal = (total: number) => {
    this.setState({
      couponTotal: total,
    })
  }

  public setUpdateCoupon = (val: boolean) => {
    this.setState({
      updateCoupon: val,
    })
  }

  public setCartPricing = (data: any) => {
    this.setState({
      cartPricing: {
        ...data,
      },
    })
  }

  public setCartCoupon = async (code: string) => {
    if (code !== '') {
      const { configuration } = this.props
      let subtotal: number = this.getSubtotal()
      if (configuration && configuration.calculations && configuration.calculations.getSubTotal) {
        subtotal = configuration.calculations.getSubTotal(this.state.cart)
      }

      let noMapSubtotal: number = this.getNotMapSubTotal()
      if (configuration && configuration.calculations && configuration.calculations.getNotMapSubTotal) {
        noMapSubtotal = configuration.calculations.getNotMapSubTotal(this.state.cart)
      }

      const codeStatus = await isCouponCodeValid(
        code,
        subtotal,
        noMapSubtotal,
        this.props.transport,
        this.props?.isCouponValidHook || undefined,
        this.state?.cart || undefined,
        this.setCouponTotal || undefined,
      )

      await CMS_API_CALLS.CART.applyCouponToCart(this.props.transport, {
        populate: false,
        couponCode: code,
        identity: this.getIdentity(),
      })

      if (codeStatus.status === COUPONVALIDATION.VALID && codeStatus.coupon) {
        const cartSummaryPricing = await this.getCartTotal(this.state.cart, undefined, codeStatus.coupon)
        this.setState({
          cartPricing: {
            ...cartSummaryPricing,
          },
          coupon: codeStatus.coupon,
          cartCoupon: codeStatus.coupon,
          couponStatus: COUPONVALIDATION.VALID,
        })
        return
      }
      const updatedPricing: ICartPrice = await this.getCartTotal(this.state.cart)
      this.setState({
        cartPricing: updatedPricing,
        coupon: null,
        couponStatus: codeStatus.status,
      })
    } else {
      await CMS_API_CALLS.CART.applyCouponToCart(this.props.transport, {
        populate: false,
        couponCode: '',
        identity: this.getIdentity(),
      })
      const cartSummaryPricing = await this.getCartTotal(this.state.cart, undefined, null)
      this.setState({
        cartPricing: {
          ...cartSummaryPricing,
        },
        coupon: null,
        couponStatus: COUPONVALIDATION.NONE,
      })
    }
  }

  // -------------------------------------------------------  CART API METHODS ---------------------------------

  public allowAddtoCart = async (item: { [x: string]: any }): Promise<string> => {
    const preHook: any = this.props.preAddtoCartEventHook
    let errorMessage: string = ''
    if (preHook !== undefined) {
      const prehookResponse: string = await preHook(this.state.cart, item)
      if (prehookResponse !== '') {
        errorMessage = prehookResponse
        this.setState({
          error: prehookResponse,
        })
      }
    }
    return errorMessage
  }

  public getCartCountFromServer = async () => {
    try {
      const response: IApiGetCartResponse = await CMS_API_CALLS.CART.getItems(this.props.transport, {
        populate: false,
        identity: this.getIdentity(),
      })
      return response.data?.count || 0
    } catch (e) {
      return 0
    }
  }

  public cartTotal = async (cartProp: ICartItem[]) => {
    const cartPrice = await this.getCartTotal(cartProp)
    return cartPrice
  }

  public getCartTotal = async (cart: ICartItem[], address?: IAddress, coupon?: ICoupon | null): Promise<ICartPrice> => {
    let response: ICartPrice = await this.props.getCartTotal(cart, address)
    let couponDiscount: number
    if (this.props.configuration?.calculations?.couponDiscountCalculationHook) {
      couponDiscount = this.props.configuration?.calculations?.couponDiscountCalculationHook({
        cartItems: cart,
        coupon: coupon || null,
        subTotal: response?.subTotal,
        notMapSubTotal: response?.notMapSubTotal,
        couponTotal: this.state.couponTotal,
      })
    } else {
      couponDiscount =
        coupon && this.doUseCoupon()
          ? calculateCouponDiscount(
              coupon,
              response.subTotal,
              response.notMapSubTotal,
              this.props?.isCouponValidHook || undefined,
              this.state?.cart || undefined,
            )
          : 0
    }
    if (this.props.configuration?.calculations?.getTaxes) {
      const taxInfo = await this.props.configuration?.calculations?.getTaxes(cart, couponDiscount, address)
      response = {
        ...response,
        ...taxInfo,
      }
    }
    return {
      ...response,
      coupon: couponDiscount,
      couponCode: coupon?.code,
    }
  }

  public mergeCart = async (params: IApiCartGenericRequestParameters) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.mergeCart(this.props.transport, params)
    if (!response.error) {
      this.setState({ anonId: '' })
      if (this.props.eventHooksMethod) {
        this.props.eventHooksMethod('merge')
      }
    }
    this.setCartState(response)
    return response
  }

  public copyCart = async (params: { [x: string]: string }) => {
    const response: any = await CMS_API_CALLS.CART.copyCart(this.props.transport, params)

    if (!response.error) {
      this.setState({ anonId: response.data?.anonId || '' })
      if (this.props.eventHooksMethod) {
        this.props.eventHooksMethod('copy')
      }
    }
    this.setCartState(response)
    return response
  }

  public clearCart = async (params: IApiCartGenericRequestParameters) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.clearCart(this.props.transport, {
      populate: params.populate,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    if (response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    this.setCartState(response)
    return response
  }

  public getItems = async (params: IApiCartGenericRequestParameters) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.getItems(this.props.transport, {
      populate: params.populate,
      identity: this.getIdentity(),
    })
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public addItemToCart = async (params: IApiCartItemRequestParameters) => {
    const allowAdd: string = await this.allowAddtoCart(params)
    if (allowAdd !== '') {
      return {
        error: allowAdd,
        data: null,
      }
    }
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.addItemToCart(this.props.transport, {
      ...params,
      populate: params.populate,
      productId: params.productId,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public removeItemFromCart = async (params: IApiCartItemRequestParameters) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.removeItemFromCart(this.props.transport, {
      populate: params.populate,
      productId: params.productId,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public clearSelection = async (params: IApiCartGenericRequestParameters, productIds: any[]) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.clearSelection(
      this.props.transport,
      {
        populate: params.populate,
        identity: this.getIdentity(),
      },
      productIds,
    )
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public deleteItemFromCart = async (params: IApiCartItemRequestParameters) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.deleteItemFromCart(this.props.transport, {
      populate: params.populate,
      productId: params.productId,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public updateItemQuantity = async (params: IApiQuantityCartRequestParameters) => {
    const allowAdd: string = await this.allowAddtoCart(params)
    if (allowAdd !== '') {
      return {
        error: allowAdd,
        data: null,
      }
    }
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.updateItemQuantity(this.props.transport, {
      ...params,
      populate: params.populate,
      productId: params.productId,
      quantity: params.quantity,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  // -----------------------------------------------------  END CART API METHODS ---------------------------------
  public applyCartSummaryToState = async (summary: ICartSummary) => {
    const { coupon, items } = summary
    const newPricing = await this.getCartTotal(items, undefined, coupon)
    this.state.couponTotal
      ? this.setState({
          couponTotal: newPricing.subTotal,
        })
      : null
    this.setState({
      cartPricing: newPricing,
      coupon: coupon && this.doUseCoupon() ? coupon : null,
      couponStatus:
        coupon && this.doUseCoupon()
          ? isCouponValid(
              coupon,
              newPricing.subTotal,
              newPricing.notMapSubTotal,
              this.props?.isCouponValidHook || undefined,
              this.state?.cart || undefined,
              this.setCouponTotal || undefined,
            ).status
          : COUPONVALIDATION.NONE,
    })
  }

  public preCalculateCartTotal = async (props: ICalculateCartProps): Promise<ICartPrice> => {
    const items: ICartItem[] = props.cart ? props.cart : this.state.cart
    const coupon: ICoupon | null = this.doUseCoupon() ? this.state.coupon : null
    return this.getCartTotal(items, props.address, coupon)
  }

  public getSubtotal = (): number => {
    let subTotal: number = 0
    this.state.cart.forEach((cartItem: ICartItem) => {
      subTotal += getSmallestPrice(cartItem.product) * cartItem.quantity
    })
    return subTotal
  }
  public getNotMapSubTotal = (): number => {
    let notMapSubTotal: number = 0
    this.state.cart.forEach((cartItem: ICartItem) => {
      if (cartItem.product.mapEnforced === false) {
        notMapSubTotal += getSmallestPrice(cartItem.product) * cartItem.quantity
      }
    })
    return notMapSubTotal
  }

  public getCartCount = () => {
    const { cart } = this.state
    const { initialCartCount } = this.props
    let count: number = 0
    for (const cartItem of cart) {
      count += cartItem.quantity
    }
    if (count > 0) {
      return count
    }
    if (initialCartCount !== undefined) {
      return initialCartCount
    }
    return count
  }

  public render() {
    const { children } = this.props
    const exportedValues: ICartContextProviderProps = {
      methods: {
        getItems: this.getItems,
        clearCart: this.clearCart,
        addItemToCart: this.addItemToCart,
        removeItemFromCart: this.removeItemFromCart,
        deleteItemFromCart: this.deleteItemFromCart,
        clearSelection: this.clearSelection,
        updateItemQuantity: this.updateItemQuantity,
        mergeCart: this.mergeCart,
        copyCart: this.copyCart,
        getOrderSessionId: this.getOrderSessionId,
      },
      cart: this.state.cart,
      coupon: this.state.coupon,
      cartCoupon: this.state.cartCoupon,
      error: this.state.error,
      count: this.getCartCount(),
      getCartTotal: this.preCalculateCartTotal,
      doUseCoupon: this.doUseCoupon,
      setCartCoupon: this.setCartCoupon,
      cartPricing: this.state.cartPricing,
      setCartPricing: this.setCartPricing,
      couponTotal: this.state.couponTotal,
      setUpdateCoupon: this.setUpdateCoupon,
      getCartCoupon: this.getCartCoupon,
      getCouponStatus: this.getCouponStatus,
      getCartCountFromServer: this.getCartCountFromServer,
    }
    return <CartContext.Provider value={exportedValues}>{children}</CartContext.Provider>
  }
}

export default {
  Context: CartContext,
  Consumer: CartContext.Consumer,
  Provider: CartDataProvider,
}
