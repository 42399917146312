import LOCATIONS from './locations'
import ACCOUNT from './account'
import PUBLIC_CONFIGS from './publicConfigs'
import CART from './cart'
import COLLECTION from './collection'
import PRODUCT_GROUP from './productGroups'
import PAYMENT from './payment'
import BLOG from './blog'

export const CMS_API_CALLS = {
  LOCATIONS,
  ACCOUNT,
  PUBLIC_CONFIGS,
  CART,
  COLLECTION,
  PRODUCT_GROUP,
  PAYMENT,
  BLOG,
}
