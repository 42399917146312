import Axios from 'axios'
import getConfig from 'next/config'
import { GERS_SERVICE } from '../../../../../settings/variables'
import moment from 'moment'
const { publicRuntimeConfig } = getConfig()
export const CMS_API = publicRuntimeConfig.CMS_API || 'http://localhost:1337'
export const CACHE_ENDPOINT = publicRuntimeConfig.CACHE_ENDPOINT || ''

export interface IGersDeliveryResponse {
  error: string
  data: IGersDeliverySummary | null
}
export interface IGersDeliverySummary {
  dates: IDates[]
  defaultDate: string
  zone: string
}

export interface IGersZoneCodeResponse {
  error: string
  data: IGersZoneCode | null
}

export interface IGersZoneCode {
  zoneCode: string
}

export interface IDates {
  date: string
  available: boolean
}

export enum IPickupDeliveryType {
  DELIVERY = 'D',
  PICKUP = 'P',
}

const ENDPOINTS = {
  availableDeliveryDates: 'v1/delivery/availableDeliveryDates',
  zoneCode: 'v1/delivery/zonecode',
  zoneCodeAndAvailabilityDates: 'v1/delivery/zoneandavaildt',
}

/**
 * Returns the list of available delivery dates given given a pickup or delivery code (P or D), a zone code and a begin date.
 *
 * @param pickupOrDeliveryCode
 * @param zoneCode
 * @param numberOfDays
 * @param beginDate
 * @returns
 */
const getAvailableDeliveryDates = async (
  pickupOrDeliveryCode: IPickupDeliveryType,
  zoneCode?: string | null,
  numberOfDays?: number,
  beginDate?: string,
): Promise<IGersDeliveryResponse> => {
  try {
    const deliveryUrl = ENDPOINTS.availableDeliveryDates
    const endpoint = `${GERS_SERVICE}/${deliveryUrl}`
    const response = await Axios.post(endpoint, {
      pickupOrDeliveryCode,
      zoneCode,
      numberOfDays,
      beginDate: beginDate || moment().format('yyyy-MM-DDTHH:MM:SS.000+0000'),
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

/**
 * Returns the zone code given a pickup or delivery code (P or D), a storeCode or warehouseCode and a zipCode.
 *
 * @param pickupOrDeliveryCode
 * @param storeCode
 * @param zipCode
 */
const getZoneCode = async (
  pickupOrDeliveryCode: IPickupDeliveryType,
  storeCode: string,
  zipCode: string,
): Promise<IGersZoneCodeResponse> => {
  try {
    const deliveryUrl = ENDPOINTS.zoneCode
    const endpoint = `${GERS_SERVICE}/${deliveryUrl}?pickupOrDeliveryCode=${pickupOrDeliveryCode}&storeCode=${storeCode}&zipCode=${zipCode}`
    const response = await Axios.get(endpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

/**
 * Determines the zone code and dates available for delivery or pickup.
 *
 * @param cartId: string,
 * @param zipCode: string,
 * @param numberOfDays: number,
 * @param pickupStoreCode: string,
 * @param pickupDeliveryDate: string,
 * @param pickupOrDeliveryCode: IPickupDeliveryType
 *
 * @returns IGersDeliveryResponse
 */
const getAvailableDeliveryDatesZoneCode = async (
  cartId: string,
  zipCode: string,
  numberOfDays: number,
  pickupDeliveryDate: string,
  pickupOrDeliveryCode: IPickupDeliveryType,
  pickupStoreCode?: string,
): Promise<IGersDeliveryResponse> => {
  try {
    const zoneCodeAndAvailabilityDatesUrl = ENDPOINTS.zoneCodeAndAvailabilityDates
    const endpoint = `${GERS_SERVICE}/${zoneCodeAndAvailabilityDatesUrl}`
    const response = await Axios.post(endpoint, {
      cartId,
      zipCode,
      numberOfDays,
      pickupStoreCode,
      pickupDeliveryDate: pickupDeliveryDate || moment().format('yyyy-MM-DDTHH:MM:SS.000+0000'),
      pickupOrDeliveryCode,
    })

    console.log(response)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    console.log(e)
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getAvailableDeliveryDates,
  getZoneCode,
  getAvailableDeliveryDatesZoneCode,
}
