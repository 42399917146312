import axios, { AxiosResponse } from 'axios'
import { NextPageContext } from 'next'
import { IAccount } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Account'
import {
  ILatLng,
  ILocation,
  IRedirect,
} from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/interfaces'
import CACHE_CONTSTANT from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/cache/constants'
import uuid from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/uuid'
import { ICartUpdatedResponse } from '../../components/Tenant/Context/MorCartContext'
import {
  CART_COUNT_COOKIE_NAME,
  GEOCODING_KEY,
  GOOGLE_GEOCODING_ENDPOINT,
  GOOGLE_GEOCODING_US_ENDPOINT,
} from '../../settings/variables'
import { getCookie, removeCookie, setCookie } from '../../utils/cookie'
import getDefaultCmsTransport from './cmsInterceptor'
import queryString from 'query-string'
import { CMS_API_CALLS } from '../../components/Tenant/Services/CMS'
import { DEFAULT_STORE_CODES, ILocationCookieType } from '../../components/StoreLocator/interfaces'
import { GERS_API_CALLS } from '../../components/Tenant/Services/GERS'
import {
  IGersStoreResponse,
  IGersStoreSummary,
  IStoreCodes,
} from '../../components/Tenant/Services/GERS/Pricing/storelocator'
import { IStoreLocation } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'

interface IAssociateAccountID {
  anonId?: string
  _id?: string
}

/**
 * Prep data for pushing into our UserContext
 */
const prepareAccount = (props: any): IAccount | null => {
  let { account } = props
  if (account) {
    if (typeof account === 'string') {
      account = JSON.parse(unescape(account))
    }
  } else {
    account = null
  }
  return account
}

/**
 * If user try to interact with the cart and they are not logged in
 * we generate a token
 */
const generateAnonId = (props: any): string => {
  const { account, anonId } = props
  if (!account && !anonId) {
    const cartId: string | undefined = getCookie('anonId', null)
    if (cartId) {
      return cartId
    }
    const newId: string = uuid()
    setCookie('anonId', newId)
    return newId
  }
  return anonId
}

// ------------------------------------------------------------ CART ----------------------------------------------------

const onCartEventHook = (type: string, params?: ICartUpdatedResponse) => {
  switch (type) {
    case 'merge':
      removeCookie('anonId')
      break
    case 'updated':
      if (params) {
        setCookie(CART_COUNT_COOKIE_NAME, params.count)
      }
      break
  }
}

/**
 * If Associate App and we have an active user then override the existing logged in user
 * We do this to simulate different customers adding items to cart
 */
const generateAccount = (account: IAccount | null, activeCustomerId: IAssociateAccountID | null) => {
  if (activeCustomerId) {
    if (activeCustomerId._id) {
      return { user: { _id: activeCustomerId._id } } as IAccount
    }
    if (activeCustomerId.anonId) {
      return null
    }
  }
  return account
}

const getLatLng = async () => {
  const coords: AxiosResponse = await axios.post(
    `https://www.googleapis.com/geolocation/v1/geolocate?key=${GEOCODING_KEY}`,
  )
  const lat: number = roundCoordinates(coords.data.location.lat)
  const lng: number = roundCoordinates(coords.data.location.lng)
  return { lat, lng }
}

const roundCoordinates = (value: number) => {
  // decimal places now is 2
  const factor = 10 ** 2
  return Math.round(value * factor) / factor
}

const getLocationUS = async (latLng: ILatLng) => {
  const location: AxiosResponse = await axios.post(
    `${GOOGLE_GEOCODING_US_ENDPOINT}&latlng=${latLng.lat},${latLng.lng}&key=${GEOCODING_KEY}&result_type=postal_code`,
  )

  if (location.data.error_message) {
    throw location.data.error_message
  }
  return location.data
}

const getLocation = async (latLng: ILatLng) => {
  const location: AxiosResponse = await axios.post(
    `${GOOGLE_GEOCODING_ENDPOINT}?latlng=${latLng.lat},${latLng.lng}&key=${GEOCODING_KEY}&result_type=postal_code`,
  )

  if (location.data.error_message) {
    throw location.data.error_message
  }
  return location.data
}

const isABot = () => {
  const botPattern =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'
  const re = new RegExp(botPattern, 'i')
  const userAgent = navigator.userAgent
  return re.test(userAgent)
}

const getStoreList = async () => {
  try {
    const finalEndpoint: string = `/locations`
    const response = await getDefaultCmsTransport(true).get(finalEndpoint, {
      headers: { cacheKey: `${CACHE_CONTSTANT.FRONTEND.MISC.LOCATIONS}-all` },
    })

    let storeList = response?.data
    if (storeList?.length > 0) {
      storeList = storeList.filter((location: { locationType: string }) => location.locationType !== 'I')
    }
    return storeList
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const checkIsUS = (addressComponents: any) => {
  return addressComponents.find((ac: { short_name: string }) => ac.short_name === 'US')
}

const findProp = (key: string, results: any[]) => {
  let value: string = ''
  for (const result of results) {
    const addressComponents = result.address_components
    for (const component of addressComponents) {
      if (component.types.indexOf(key) !== -1) {
        value = component.short_name
        break
      }
    }
    if (checkIsUS(addressComponents)) {
      break
    }
  }
  return value
}

/**
 * We should move all redirects out to an nginx file. It isn't efficient to call strapi / cache each time
 * to check if we should redirect.
 * @param ctx
 */
const checkRedirects = async (ctx: NextPageContext) => {
  try {
    const requestedUrlParts: string[] = ctx.req?.url?.split('?') || []
    if (requestedUrlParts?.length > 0) {
      const currentBasePath: string = requestedUrlParts[0]
      const existingParameters: string | undefined = requestedUrlParts[1]
      //if _next is present, it is always internal assets such images images and javascript.
      //we only want to redirect at the page level
      if (currentBasePath.indexOf('/_next/') === -1) {
        const redirectResponse: AxiosResponse = await getDefaultCmsTransport(true).get(
          `/redirects?_limit=1&source=${currentBasePath}`,
          {
            headers: { cacheKey: `${CACHE_CONTSTANT.FRONTEND.MISC.REDIRECTS}-${currentBasePath}` },
          },
        )
        if (redirectResponse.data?.length) {
          //array will always be either empty or max of 1 since we pass _limit=1
          const targetRedirectObject: IRedirect = redirectResponse.data[0]
          let redirectUrl: string = targetRedirectObject.target
          //let's get our redirect code: 301, 302, or 308
          let redirectCode: number = 301 //alawys default to 301
          if (targetRedirectObject.code) {
            redirectCode =
              typeof targetRedirectObject.code !== 'number'
                ? parseInt(targetRedirectObject.code)
                : (targetRedirectObject.code as number)
          }
          //after, let's determine if we want to keep the parameters, we always default to yes
          if (targetRedirectObject.keepParameters === 'keep' && existingParameters) {
            redirectUrl = `${targetRedirectObject.target}?${existingParameters}`
          }
          ctx.res?.writeHead(redirectCode, {
            Location: redirectUrl,
          })
          ctx.res?.end()
        }
      }
    }
  } catch (e) {
    console.log(`Error while redirecting: ${e.toString()}`)
  }
}

interface IRegionIdQueryParam {
  region_id?: string
}

const getRegionIdFromUrl = (): string | null => {
  if (window?.location?.search) {
    const queryParams: IRegionIdQueryParam = queryString.parse(window.location.search)
    if (queryParams.region_id) {
      return queryParams.region_id
    }
  }
  return null
}

/**
 * Translate a string with seven numbers: 1087076 into a two letters warehouseCode: WL
 * The string to translate is built with a leading sequence number (1 or 2) followed by six more
 * numbers that consist in the two ascii codes for each warewhouseCode letter with a leading 0
 * For instance: region_id = 1087076 --> leading sequence: 1, leading 0, ascii for W: 87, leading 0, ascii for L: 76.
 *
 * @param regionId A string with seven numbers.
 * @returns A string with two letters.
 */
const decodeRegionId = (regionId: string): string | null => {
  const withoutLeadingNumbers = regionId.slice(2)
  const asciiCharArray = withoutLeadingNumbers.replace('0', ',').split(',')
  if (asciiCharArray.length === 2) {
    return String.fromCharCode(Number(asciiCharArray[0])) + String.fromCharCode(Number(asciiCharArray[1]))
  }

  return null
}

interface IRegionLocation {
  regionStoreCodes: IGersStoreSummary
  regionLocationDetails: IStoreLocation
}

const getRegionLocation = async (): Promise<IRegionLocation | null> => {
  const regionId = getRegionIdFromUrl()
  if (regionId) {
    const warehouseCode = decodeRegionId(regionId)
    if (warehouseCode) {
      const regionLocation = await CMS_API_CALLS.LOCATIONS.getSingleLocationByStoreCode(
        getDefaultCmsTransport(true),
        warehouseCode,
      )
      if (regionLocation?.data?.zip) {
        const response: IGersStoreResponse = await GERS_API_CALLS.STORELOCATOR.getClosestStoreInfo(
          regionLocation.data.zip,
        )

        return response.data ? { regionStoreCodes: response.data, regionLocationDetails: regionLocation.data } : null
      }
    }
  }
  return null
}

const setStoreCodesAndLocation = async (
  geoLocation: ILocation | null,
  setClosestStore: (codes: IStoreCodes) => void,
  updateGeoLocationInStateAndCookie: (geoLocation: ILocation) => void,
) => {
  // a geolocation object should always be returned from the server if the user agent is a browser
  if (geoLocation) {
    //check to see if the cookie has already been set, if not, we want to set it
    //so we don't keep pinging google api
    const currentLocationString: string | undefined = getCookie(ILocationCookieType.GEOLOCATION, null)
    if (!currentLocationString) {
      setCookie(ILocationCookieType.GEOLOCATION, geoLocation)
    }
    //check if we already have the cookie with closest store from gers
    const currentStoreCodesString: string | undefined = getCookie(ILocationCookieType.STORE_CODES, null)
    if (!currentStoreCodesString) {
      let codes = DEFAULT_STORE_CODES
      const response: any = await GERS_API_CALLS.STORELOCATOR.getClosestStoreInfo(geoLocation.zip)
      if (response.error) {
        setCookie(ILocationCookieType.STORE_CODES, codes)
      }
      // valid geolocation has to have a zipcode
      if (response.data && geoLocation.zip) {
        codes = { storeCode: response.data.storeCode || 'XX', warehouseCode: response.data.warehouseCode || 'WX' }
        setCookie(ILocationCookieType.STORE_CODES, codes)
        setClosestStore(codes)
      } else {
        setCookie(ILocationCookieType.STORE_CODES, codes)
        setClosestStore(codes)
      }
    } else {
      const codes: IStoreCodes = JSON.parse(unescape(currentStoreCodesString)) as IStoreCodes
      setClosestStore(codes)
    }
  }

  /**
   * If there's a region_id param on the url, set the storeCodes cookie and Closest Store to display the right prices for that region.
   * Update the geolocation to be consistent.
   */
  const regionLocation = await getRegionLocation()
  if (regionLocation?.regionStoreCodes && regionLocation.regionLocationDetails) {
    const codes = {
      storeCode: regionLocation.regionStoreCodes.storeCode || 'XX',
      warehouseCode: regionLocation.regionStoreCodes.warehouseCode || 'WX',
    }
    setCookie(ILocationCookieType.STORE_CODES, codes)
    setClosestStore(codes)
    const { regionLocationDetails } = regionLocation
    const geoLocation = {
      state: regionLocationDetails.state,
      city: regionLocationDetails.city,
      zip: regionLocationDetails.zip,
      latLng: {
        lat: regionLocationDetails.latitude,
        lng: regionLocationDetails.longtitude,
      },
    }
    updateGeoLocationInStateAndCookie(geoLocation)
  }
}

export {
  prepareAccount,
  generateAnonId,
  onCartEventHook,
  generateAccount,
  isABot,
  getStoreList,
  findProp,
  getLocation,
  getLocationUS,
  getLatLng,
  checkRedirects,
  setStoreCodesAndLocation,
  roundCoordinates,
}
