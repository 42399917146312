import getDefaultCmsTransport from '../../../../../services/api/cmsInterceptor'
import { getCookie } from '../../../../../utils/cookie'
import { ITransport } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import { buildFinancePaymentRequest } from '../../../../Utils/checkoutUtils'
import { ICheckoutComponentProps, ICheckoutComponentState } from '../../../Layout/Checkout/Checkout'
import { IFinanceCardType } from './customer'
import moment from 'moment'
import { ICheckoutFormState } from '../../../Layout/Checkout'

const ENDPOINTS = {
  DBUY_AUTH: 'v1/api/finance/dbuyauth',
  DBUY_COMPLETE: 'v1/api/finance/dbuycomplete',
}

export interface IDbuyAuthenticationResponse {
  error: string
  data: {
    dbuyToken: string
    merchantId: string
    isError: boolean
    message: string
  } | null
}
export interface IDbuyCompleteRequest {
  cardType: string
  token: string
  address1: string
  address2: string
  city: string
  state: string
  zipCode: string
  amount: string
  promoCode: string
}
export interface IDbuyCompleteResponse {
  error: string
  data: {
    authCode: string
    accountNumber: string
    statusTitle: string
    statusBody: string
    error: boolean
    responseMessage: string
  } | null
}

interface IAccountInformationModalRequest {
  tokenId: string
  merchantID: string
  processInd: '1' | '3'
  clientTransId: string
  transAmount1: string
  transPromo1: string
  custState: string
  custCity: string
  custZipCode: string
  custAddress1: string
  custAddress2: string
}

interface ISyfDBuyLibrary {
  calldBuyProcess: (param1: null, param2: IAccountInformationModalRequest) => void
}
declare const syfDBuy: ISyfDBuyLibrary

const getDBuyToken = async (): Promise<IDbuyAuthenticationResponse> => {
  try {
    const transport: ITransport = getDefaultCmsTransport(false, true, true)
    const endpoint: string = `/${ENDPOINTS.DBUY_AUTH}?cardType=${IFinanceCardType.SYF}`
    const response = await transport.get(endpoint)
    return { error: '', data: response.data }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const callDBuyComplete = async (
  dbuyToken: string,
  financedAmount: number,
  promoCode: string,
  formState: ICheckoutFormState,
): Promise<IDbuyCompleteResponse> => {
  const amount = financedAmount.toString()

  try {
    const transport: ITransport = getDefaultCmsTransport(false, true, true)
    const endpoint: string = `/${ENDPOINTS.DBUY_COMPLETE}?cardType=${IFinanceCardType.SYF}`
    const request: IDbuyCompleteRequest = {
      cardType: IFinanceCardType.SYF,
      token: dbuyToken,
      address1: formState.billing.street1 || formState.shipping.street1,
      address2: formState.billing.street2,
      city: formState.billing.city.toUpperCase(),
      state: formState.billing.state,
      zipCode: formState.billing.zip,
      amount,
      promoCode,
    }
    const response = await transport.post(endpoint, request)
    return { error: '', data: response.data }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const callDigitalBuyCombinedModal = async (
  dbuyToken: string,
  merchantId: string,
  financedAmount: number,
  promotionCode: string,
  formState: ICheckoutFormState,
) => {
  const amount = financedAmount.toString()

  syfDBuy.calldBuyProcess(null, {
    tokenId: dbuyToken,
    merchantID: merchantId,
    processInd: '3',
    transAmount1: amount,
    clientTransId: getCookie('anonId'),
    transPromo1: promotionCode,
    custState: formState.billing.state,
    custCity: formState.billing.city.toUpperCase(),
    custZipCode: formState.billing.zip,
    custAddress1: formState.billing.street1 || formState.shipping.street1,
    custAddress2: formState.billing.street2,
  })
}

const NOT_AUTHORIZED = 'Not Authorized'

const onCloseDigitalBuyCombinedModal = async (
  checkoutState: ICheckoutComponentState,
  checkoutProps: ICheckoutComponentProps,
) => {
  if (
    checkoutState.dbuyToken &&
    checkoutState.morCardFinanceOptionSelected?.financedAmount &&
    checkoutState.morCardFinanceOptionSelected?.promotionCode &&
    checkoutState.orderInProgress
  ) {
    const response = await callDBuyComplete(
      checkoutState.dbuyToken,
      checkoutState.morCardFinanceOptionSelected.financedAmount,
      checkoutState.morCardFinanceOptionSelected.promotionCode,
      checkoutState.formsState,
    )

    if (response.data?.authCode) {
      //if (true) {
      const deliveryDate =
        checkoutState.estimatedDelivery && !checkoutState.needByDateEnabled ? checkoutState.estimatedDelivery : ''

      let needByDate = checkoutState.needByDate

      if (needByDate) {
        needByDate = moment(needByDate)
          .utc()
          .format()
      }
      const { cartPrice, formsState, sameBilling, morCardFinanceOptionSelected } = checkoutState
      const { cartItems, events } = checkoutProps

      const request = buildFinancePaymentRequest(
        cartPrice,
        formsState,
        sameBilling,
        cartItems,
        morCardFinanceOptionSelected,
        response,
      )
      return await events.submitOrder(
        request,
        deliveryDate,
        needByDate || '',
        checkoutState?.authNetResponse || undefined,
      )
    } else {
      return {
        error: NOT_AUTHORIZED,
      }
    }
  }
  return {
    error: '',
  }
}

const onSubmitOrderMorFinance = async (
  checkoutState: ICheckoutComponentState,
  setDbuyTokenCallback: (dBuydbuyTokenParam: string) => void,
) => {
  const response: IDbuyAuthenticationResponse = await getDBuyToken()
  const { morCardFinanceOptionSelected, formsState } = checkoutState
  if (response.data && morCardFinanceOptionSelected?.financedAmount && morCardFinanceOptionSelected?.promotionCode) {
    const { dbuyToken, merchantId } = response.data
    setDbuyTokenCallback(dbuyToken)
    await callDigitalBuyCombinedModal(
      dbuyToken,
      merchantId,
      morCardFinanceOptionSelected?.financedAmount,
      morCardFinanceOptionSelected.promotionCode,
      formsState,
    )
  }
}

export default {
  onSubmitOrderMorFinance,
  onCloseDigitalBuyCombinedModal,
  NOT_AUTHORIZED,
}
