import Axios from 'axios'
import getConfig from 'next/config'
import { GERS_SERVICE } from '../../../../../settings/variables'
const { publicRuntimeConfig } = getConfig()
export const CMS_API = publicRuntimeConfig.CMS_API || 'http://localhost:1337'
export const CACHE_ENDPOINT = publicRuntimeConfig.CACHE_ENDPOINT || ''

export interface IGersVoucherResponse {
  error: string
  data: IGersVoucherSummary | null
}
export interface IGersVoucherSummary {
  voucherId: number
  amount: string
  amountTypeId: string
  discountTypeId: string
  status: string
}

const ENDPOINTS = {
  validateCoupon: 'v1/validatecoupon',
  deleteCoupon: 'v1/deletecoupon',
}

/**
 * Determines a coupon code/voucher is a valid input on checkout.
 *
 * @param couponCode: string,
 * @param email: string,
 * @param shoppingCartId: number,
 *
 * @returns IGersVoucherResponse
 */
const validateCoupon = async (
  couponCode: string,
  email: string,
  shoppingCartId?: number,
): Promise<IGersVoucherResponse> => {
  try {
    const endpoint = `${GERS_SERVICE}/${ENDPOINTS.validateCoupon}`
    const response = await Axios.post(endpoint, {
      shoppingCartId,
      couponCode,
      email,
      customerCode: '',
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    console.log(e)
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

/**
 * Deletes a coupon code/voucher for a given shopping cart id.
 *
 * @param shoppingCartId: number,
 * @param voucherId: number,
 *
 */
const deleteCoupon = async (shoppingCartId: number, voucherId: number): Promise<IGersVoucherResponse> => {
  try {
    const endpoint = `${GERS_SERVICE}/${ENDPOINTS.deleteCoupon}`
    const response = await Axios.post(endpoint, {
      shoppingCartId,
      voucherId,
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    console.log(e)
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  validateCoupon,
  deleteCoupon,
}
