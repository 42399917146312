import * as React from 'react'
import { Row, Col } from '../../../Common/Layout'
import { ICartItem, ICartEventProps } from '../..'
import ImageLoader from '../../../Common/ImageLoader/ImageLoader'
import { IProduct } from '../../../PDP'
import getSmallestPrice from '../../../Common/Utils/cart/getSmallestPrice'
import { MERCE_CLASS_NAMES } from '../../../interfaces'
import QuantityCounter from './QuantityCounter/QuantityCounter'
import css from './Item.scss'

export interface IProps {
  item: ICartItem
  events?: ICartEventProps
  renderShipsBySection?: (product: IProduct, quantity?: number) => JSX.Element
  defaultThumbnail?: string
  clearCartOption?: boolean
  onSelectionHandler?: Function
  selectionIDs?: string[]
  allowBulkSelection?: boolean
}
interface IState {
  quantity: number
  selected: boolean
}
export default class Item extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      quantity: props.item ? props.item.quantity : 1,
      selected: false,
    }
  }
  public calculateSubTotal = (): number => {
    const { item } = this.props

    const { product } = item
    if (item) {
      return item.quantity * getSmallestPrice(product)
    }
    return -1
  }
  public onQuantityChange = (num: number): void => {
    const { quantity } = this.state
    const { events } = this.props
    const target: number = quantity + num
    if (target < 1) {
      return
    }
    const item: ICartItem = this.props.item
    item.quantity = quantity + num
    if (events?.onQuantityUpdated) {
      events.onQuantityUpdated(item)
    }
    this.setState({
      quantity: quantity + num,
    })
  }
  public onDeleteItemButtonClicked = (): void => {
    const { events, item } = this.props
    if (events?.onRemoveItemFromCart) {
      events.onRemoveItemFromCart(item)
    }
  }
  public onToggleSelection = (productId: string): void => {
    const { onSelectionHandler, selectionIDs } = this.props
    if (onSelectionHandler && selectionIDs) {
      onSelectionHandler(!selectionIDs.includes(productId), productId)
    }
  }
  onNavigateToProduct = (product: IProduct) => {
    if (this.props.events?.onNavigateToProduct) {
      this.props.events.onNavigateToProduct(product)
    }
  }
  public render() {
    const { item, selectionIDs, allowBulkSelection } = this.props
    const { product, quantity } = this.props.item
    if (!item || !product) {
      return <div />
    }
    return (
      <div className={`${css.cartItem} ${MERCE_CLASS_NAMES.CART_PRODUCT_DETAIL}`} key={`${product.name}`}>
        <Row>
          <div className={css.row}>
            <Col xs={12} sm={5} noPadding={true} className={css.left}>
              <Col xs={12} sm={4} className={css.image} noPadding={true}>
                <ImageLoader
                  src={product.thumbnail || (this.props.defaultThumbnail as any)}
                  alt="thumbnail"
                  responsive={true}
                  className={css.productImage}
                />
              </Col>
              <Col xs={12} sm={8} className={css.itemInfo}>
                <div className={css.itemTitle} onClick={this.onNavigateToProduct.bind(this, product)}>
                  {product.name}
                </div>
                {product.brand && (
                  <p className={`${css.itemAttribute} ${MERCE_CLASS_NAMES.CART_PRODUCT_BRAND}`}>{product.brand}</p>
                )}
                {product.modelnumber && (
                  <p className={`${css.itemAttribute} ${MERCE_CLASS_NAMES.CART_PRODUCT_MODEL}`}>
                    Model: {product.modelnumber.toUpperCase()}
                  </p>
                )}
              </Col>
            </Col>
            <Col xs={12} sm={7} className={css.right}>
              {item.nullProduct && <p>Warning: This product no longer exists in our catalog.</p>}
              <Col
                xs={12}
                sm={6}
                noPadding={true}
                className={`${css.shipping} ${MERCE_CLASS_NAMES.CART_SHIPPING_MESSAGING}`}
              >
                {this.props.renderShipsBySection && this.props.renderShipsBySection(product, quantity)}
              </Col>
              <Col xs={12} sm={6} className={css.pricing}>
                <div className={css.quantityAndTotal}>
                  <div className={css.quantityAndPrice}>
                    <QuantityCounter quantity={quantity} onQuantityChange={this.onQuantityChange} />
                    <div className={css.price}>
                      ${getSmallestPrice(product).toFixed(2)}
                      /item
                    </div>
                  </div>
                  <div className={`${css.total} ${MERCE_CLASS_NAMES.CART_PRODUCT_SKU_PRICE}`}>
                    $
                    {this.calculateSubTotal().toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
                {allowBulkSelection && selectionIDs ? (
                  <label className={css.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={selectionIDs.includes(product.id)}
                      value={selectionIDs.includes(product.id).toString()}
                      onChange={() => this.onToggleSelection(product.id)}
                    />
                    <span className={css.checkboxCheckmark} />
                  </label>
                ) : null}

                <div className={css.removeButton}>
                  <button onClick={this.onDeleteItemButtonClicked} className={MERCE_CLASS_NAMES.CART_REMOVE_ICON}>
                    <i className="pointer far fa-trash-alt" /> Remove{' '}
                  </button>
                </div>
              </Col>
            </Col>
          </div>
        </Row>
      </div>
    )
  }
}
