import React from 'react'
import css from './Thumbnail.scss'

export interface IThumbnailProps {
  imgUrl: string
  imgAltText: string
  containerClass: string
  ariaLabel?: string
  onClick?: () => void
  objectFitCover?: boolean
}

/**
 * unified Thumbnail component to display product thumbnail images on PLPs, mini cart, cart, checkout and order confirmation.
 *
 * @param props IThumbnailProps
 * containerClass class to modify the size of the container.
 * onClick event if clicking the Thumbnail should trigger an action.
 */
const Thumbnail = (props: IThumbnailProps) => {
  const { containerClass, onClick, imgUrl, imgAltText, ariaLabel, objectFitCover } = props
  return (
    <div className={containerClass} onClick={onClick}>
      <img
        src={imgUrl}
        alt={imgAltText}
        title={imgAltText}
        className={objectFitCover ? css.productImageCover : css.productImageContain}
        aria-label={ariaLabel}
      />
    </div>
  )
}

export default Thumbnail
