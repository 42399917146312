import { IUnbxdProduct } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { IProductInStore } from '../Tenant/Layout/PDP/SeeInStore/SeeInStore'
import { ISort } from '../Tenant/Widgets/ProductDisplay/ProductDisplay'
import { getStoreCode } from './getStoreCodeFromCookie'
import { fit } from '../../utils/imageProxy'

export interface ILowestHighestFinalPrice {
  lowestFinalPrice: number
  highestFinalPrice: number
}

const getProductFinalPrice = (product: IUnbxdProduct, storeCode: string) => {
  return product.mapEnforced === true && product.map
    ? product.map
    : product[`finalPrice_${storeCode}`] !== undefined
    ? product[`finalPrice_${storeCode}`]
    : (product?.variants?.[0] as any)?.[`finalPrice_${storeCode}`]
}

const getProductStoreAvailability = (product: IUnbxdProduct) => {
  const productAvailability: IProductInStore[] = []
  const AVAILABILITY_FIELD_STARTS_WITH = 'locCode'
  for (const prop in product) {
    if (prop.startsWith(AVAILABILITY_FIELD_STARTS_WITH)) {
      const productInStore: IProductInStore = {
        key: prop,
        value: product[prop],
      }
      productAvailability.push(productInStore)
    }
  }
  return productAvailability
}

/**
 * Gets the first variant.
 *
 * @param products
 * @returns top Product in the stack
 */
const getCurrentProductFromVariants = (products: IUnbxdProduct[], sortVariantsBy?: ISort) => {
  if (sortVariantsBy) {
    return products.find(product => product[sortVariantsBy.attribute] === sortVariantsBy.value) || products[0]
  }
  return products[0]
}

/**
 * Gets the corresponding product to display on search and browse pages (PLPs).
 * For releventDocument = parent and If the category is beds OR bedroom sets display queen size first.
 * For releventDocument = variant display the first variant.
 *
 * @param products
 * @returns top Product in the stack
 */
const getCurrentProductFromVariantsPLPs = (products: IUnbxdProduct[], releventDocument?: 'parent' | 'variant') => {
  let selectedProduct = products[0]
  if (releventDocument !== 'variant') {
    const product = products.find(p => p?.BED_SIZE === 'Queen' || p?.name?.includes('Queen'))
    selectedProduct = product || products[0]
  }
  return selectedProduct
}

const getLowestAndHighestFinalPriceFromVariants = (products: IUnbxdProduct[]): ILowestHighestFinalPrice => {
  let lowestFinalPrice = null
  let highestFinalPrice = null
  const storeCode = getStoreCode()

  for (const product of products) {
    const tempFinalPrice = getProductFinalPrice(product, storeCode)
    //find LOWEST finalPrice
    if (null == lowestFinalPrice) {
      lowestFinalPrice = tempFinalPrice
    } else {
      if (null != lowestFinalPrice && tempFinalPrice < lowestFinalPrice) {
        lowestFinalPrice = tempFinalPrice
      }
    }
    //find HIGHTEST finalPrice
    if (null == highestFinalPrice) {
      highestFinalPrice = tempFinalPrice
    } else {
      if (null != highestFinalPrice && tempFinalPrice > highestFinalPrice) {
        highestFinalPrice = tempFinalPrice
      }
    }
  }
  return { lowestFinalPrice, highestFinalPrice }
}

const compareProductFinalPrice = (p1: IUnbxdProduct, p2: IUnbxdProduct) => {
  const storeCode = getStoreCode()
  if (getProductFinalPrice(p1, storeCode) < getProductFinalPrice(p2, storeCode)) {
    return -1
  }
  if (getProductFinalPrice(p1, storeCode) > getProductFinalPrice(p2, storeCode)) {
    return 1
  }
  return 0
}

const sortProductsByFinalPrice = (products: IUnbxdProduct[], sortParam: string) => {
  const sortProperty: string[] = sortParam.split(' ')
  products.sort(compareProductFinalPrice)
  if (sortProperty[1] == 'desc') {
    products.reverse()
  }
}

const getCanonicalUrl = (product: IUnbxdProduct) => {
  // for rel canonical tags
  let protocol = 'https:'
  const host = window.location.hostname
  if (host && host.indexOf('localhost') > -1) {
    protocol = 'http:'
  }

  return `${protocol}//${host}${product.productUrl}`
}

const getOptimizedImage = (imageUrl: string): string => {
  const thumbnail = imageUrl
  return thumbnail
    ? fit(thumbnail, {
        size: 300,
      })
    : '/static/morPlaceholder.jpg'
}

const getProductImageUrl = (product: IUnbxdProduct) => {
  let imageUrl = ''
  if (product.variants?.[0]?.images) {
    const images: any = product.variants?.[0]?.images
    const imagesAsObject = JSON.parse(images)
    product.images = { items: imagesAsObject.items }
    if (product.images.items[0]) {
      imageUrl = getOptimizedImage(product.images?.items[0]?.url)
    }
  }

  return imageUrl
}

export {
  getProductFinalPrice,
  getProductStoreAvailability,
  getCurrentProductFromVariants,
  sortProductsByFinalPrice,
  getLowestAndHighestFinalPriceFromVariants,
  getCurrentProductFromVariantsPLPs,
  getCanonicalUrl,
  getProductImageUrl,
}
