import getDefaultCmsTransport from '../../../../services/api/cmsInterceptor'
import getDefaultTransport from '../../../../services/api/defaultInterceptor'

const ENDPOINTS = {
  PUBLIC_CONFIGS: `publicconfigurations`,
}

export interface IStudioPublicConfigsResponse {
  error: string
  data: IStudioPublicConfigs[] | null
}

export interface IStudioPublicConfigs {
  title: string
  id: string
  keyvalues: {
    enabled: boolean
    stores?: string[]
    ips?: string[]
  }
}

export enum AVAILABLE_CONFIGS {
  NEED_BY_DATE = 'NEED_BY_DATE',
  MOR_CREDIT_CARD = 'MOR_CREDIT_CARD',
}

/**
 * Endpoint to get public configs from studio (feature flags)
 * to enable or disable certain feature.
 *
 * @param transport
 */
const getPublicConfigs = async (): Promise<IStudioPublicConfigsResponse> => {
  try {
    const transport = getDefaultCmsTransport(true)
    const finalEndpoint: string = `/${ENDPOINTS.PUBLIC_CONFIGS}`
    const response = await transport.get(finalEndpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occured',
      data: null,
    }
  }
}

const loadPublicConfigs = async (): Promise<IStudioPublicConfigs[]> => {
  const publicConfigurationsFromService = await getPublicConfigs()
  return publicConfigurationsFromService.data as IStudioPublicConfigs[]
}

/**
 * Returns the studio public configuration for a given IP address.
 *
 * @param selectedConfig
 *    the requested configuration name.
 * @returns
 *    the current configuration for a given user IP address.
 */
const getPublicConfigsForIpAddress = async (
  configurations: IStudioPublicConfigs[],
  selectedConfig: AVAILABLE_CONFIGS,
): Promise<boolean> => {
  try {
    if (configurations && Array.isArray(configurations) && configurations.length > 0) {
      const config = configurations.find(publicConfig => publicConfig.title === selectedConfig)

      if (config?.keyvalues.enabled && config.keyvalues.ips) {
        // if the feature flag is enabled, we check if the user ip address is whitelisted.
        const ipResponse = await getDefaultTransport().get('/get-ip')
        console.log('ipRes', ipResponse?.data?.ip)
        const foundIp = config.keyvalues.ips.includes(ipResponse?.data?.ip)
        return foundIp ? true : false
      }
      // if the feature flag is NOT enabled, we show functionality or UI component by default.
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

export default {
  getPublicConfigs,
  loadPublicConfigs,
  getPublicConfigsForIpAddress,
}
