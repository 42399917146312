import getDefaultCmsTransport from '../../services/api/cmsInterceptor'
import { METER_TO_MILE_EQUIVALENCE } from '../../settings/variables'
import { ILatLng, ILocation } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/interfaces'
import { IStoreLocation } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'
import { IMorStoreLocation } from '../StoreLocator/interfaces'
import { CMS_API_CALLS } from '../Tenant/Services/CMS'
import { IStoreCodes } from '../Tenant/Services/GERS/Pricing/storelocator'

const STORES_TO_EXCLUDE = ['XX', '00']
const WAREHOUSES_TO_EXCLUDE = 'W'

const isOutOfDeliveryArea = (storeCodes: IStoreCodes | null) => {
  return storeCodes !== null && storeCodes.storeCode === 'XX' && storeCodes.warehouseCode === 'WX'
}

const getDistanceToStore = (currentLocation: ILatLng | null, storeLocation: ILatLng | null) => {
  if (currentLocation && storeLocation && process.browser) {
    const google = window.google

    if (google) {
      return google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(currentLocation.lat, currentLocation.lng),
        new google.maps.LatLng(storeLocation.lat, storeLocation.lng),
      )
    }
  }
  return 0
}

const getDistanceToStoreInMiles = (currentLocation: ILatLng | null, storeLocation: ILatLng | null) => {
  return `${(getDistanceToStore(currentLocation, storeLocation) * METER_TO_MILE_EQUIVALENCE).toFixed(1)} mi`
}

const sortWarehouseList = (warehouses: IStoreLocation[], geoLocation?: ILocation | null) => {
  return warehouses?.sort((a: IStoreLocation, b: IStoreLocation) => {
    const locationA: ILatLng = {
      lat: a.latitude,
      lng: a.longtitude,
    }
    const locationB: ILatLng = {
      lat: b.latitude,
      lng: b.longtitude,
    }

    if (geoLocation) {
      return getDistanceToStore(geoLocation.latLng, locationA) - getDistanceToStore(geoLocation.latLng, locationB)
    }
    return 0
  })
}

const getPickupLocation = async (geoLocation: ILocation) => {
  const pickupLocations = await CMS_API_CALLS.LOCATIONS.getWarehouseLocations(getDefaultCmsTransport(true))
  const sortedPickupLocations = sortWarehouseList(pickupLocations, geoLocation)
  return sortedPickupLocations[0]
}

const getStoresWithDistance = (stores: IMorStoreLocation[], currentLocation: ILatLng | null) => {
  if (currentLocation) {
    return stores.map((store: IMorStoreLocation) => {
      const storeLocation: ILatLng = { lat: Number(store.latitude), lng: Number(store.longtitude) }
      let distance = getDistanceToStore(currentLocation, storeLocation)
      if (distance) {
        distance *= 0.000621371192
      }

      store.distance = Number(distance.toFixed(1)) || 0

      return store
    })
  }
  return stores
}

const sortStoresByDistance = (stores: IMorStoreLocation[], currentLocation: ILatLng | null) => {
  if (currentLocation) {
    stores.sort((a: IMorStoreLocation, b: IMorStoreLocation) => {
      const distanceA = getDistanceToStore(currentLocation, {
        lat: Number(a.latitude),
        lng: Number(a.longtitude),
      })

      const distanceB = getDistanceToStore(currentLocation, {
        lat: Number(b.latitude),
        lng: Number(b.longtitude),
      })

      return distanceA - distanceB
    })
  }
  return stores
}

const getOnlyStores = (storeList: IStoreLocation[]) => {
  return storeList.filter((store: IMorStoreLocation) => {
    return !store.legacyStoreId.startsWith(WAREHOUSES_TO_EXCLUDE) && !STORES_TO_EXCLUDE.includes(store.legacyStoreId)
  })
}

const getStoresWithinRadius = (storeList: IStoreLocation[], currentLocation: ILatLng | null, radius: number | null) => {
  const storesCopy: IMorStoreLocation[] = []
  Object.assign(storesCopy, storeList)

  const onlyStores = getOnlyStores(storesCopy)

  const storesWithDistance = getStoresWithDistance(onlyStores, currentLocation)

  const sortedStores = sortStoresByDistance(storesWithDistance, currentLocation)

  let filteredStores: IMorStoreLocation[] = []
  if (sortedStores?.length) {
    filteredStores = sortedStores
    if (radius) {
      filteredStores = sortedStores.filter((store: IMorStoreLocation) => {
        return Number(store.distance) <= radius
      })
    }
  }
  return filteredStores
}

export {
  isOutOfDeliveryArea,
  getDistanceToStore,
  sortWarehouseList,
  getDistanceToStoreInMiles,
  getPickupLocation,
  getStoresWithinRadius,
}
