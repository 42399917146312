import datadogLogger from './providers/datadog'

const logProviders: ILogProviderInterface[] = [datadogLogger]

export interface ILogProviderHookInterface {
  name: string
  settings: Record<string, any>
}

export interface ILogProviderInterface {
  initialize: (data?: Record<string, any>) => void
  hook: (config?: ILogProviderHookInterface) => void
  logMessage: (message: string, data?: any) => void
  logError: (message: string, error?: Error) => void
}

/**
 * Call this at the start of your application
 */
const initialize = () => {
  for (const provider of logProviders) {
    provider.initialize()
  }
}

/**
 * Generic method where you can pass anything in
 * @param {*} config { name:string, settings: object }
 */
const hook = (config: any) => {
  for (const provider of logProviders) {
    provider.hook(config)
  }
}

/**
 * Log generic messages here
 * @param {*} message string
 * @param {*} data object
 */
const logMessage = (message: string, data?: any) => {
  for (const provider of logProviders) {
    provider.logMessage(message, data)
  }
}

/**
 * Log generic messages here
 * @param {*} message string || Error
 */
const logError = (message: string, error?: Error) => {
  for (const provider of logProviders) {
    provider.logError(message, error)
  }
}

export default { initialize, logMessage, logError, hook } as ILogProviderInterface
