import * as React from 'react'
import css from './Chevron.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

interface IChevronProps {
  side: 'left' | 'right' | 'up' | 'down'
  onClick: () => void
}

const renderChevron = (side: string) => {
  switch (side) {
    case 'left':
      return faChevronLeft
    case 'right':
      return faChevronRight
    case 'up':
      return faChevronUp
    case 'down':
      return faChevronDown
    default:
      break
  }
  return faChevronLeft
}

const Chevron: React.FunctionComponent<IChevronProps> = props => {
  return (
    <div role="presentation" onClick={props.onClick} tabIndex={0}>
      <FontAwesomeIcon
        icon={renderChevron(props.side)}
        fixedWidth
        className={css.carouselIcon}
        aria-label={`Navigate to ${props.side}`}
      />
    </div>
  )
}

export default Chevron
