import React from 'react'
import { ICartItem } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import { IActiveOptions, ICustomActiveOptions, ICustomAddons, IPDPProduct } from '../Tenant/Layout/PDP/PDP'
import { fit } from '../../utils/imageProxy'
import queryString from 'query-string'
import { HYPHEN_REGEX, SPACE_REGEX } from '../../settings/variables'
import { IAttributeSwatch } from '../Tenant/Layout/PDP/Swatches/Swatches'

const CUSTOM_ADDON_GROUP_CODE = 'custom-addon'

const cylindoCodes = {
  ACCENT_COLOR: 'ACCENT_PILLOW',
  ACCENT_COLOR2: 'ACCENT_PILLOW2',
  ACCENT_COLOR3: 'ACCENT_PILLOW3',
  BODY_COLOR: 'COLOR',
  COLOR: 'COLOR',
  FACING: 'FACING',
  MOTION_TYPE: 'MOTION_TYPE',
  ADJ_FOUNDATION: 'ADJ_FOUNDATION',
  BED_FOUND_NEED: 'BED_FOUND_NEED',
  BED_SIZE: 'BED_SIZE',
  MATTRESS_COMFORT_LVL: 'MATTRESS_COMFORT_LVL',
  POWER_TYPE: 'POWER_TYPE',
  SEAT_BACK_TYPE: 'SEAT_BACK_TYPE',
  SEAT_COMP: 'SEAT_COMP',
  SIZE_CLASS: 'SIZE_CLASS',
  STORAGE_COMPARTMENT: 'STORAGE_COMPARTMENT',
  STORAGE_TYPE: 'STORAGE_TYPE',
  TABLE_HEIGHT_CLASS: 'TABLE_HEIGHT_CLASS',
  TABLE_SHAPE: 'TABLE_SHAPE',
  TV_SIZE: 'TV_SIZE',
} as Record<string, string>

const cylindoLabels = {
  ACCENT_COLOR: 'Accent Color',
  ACCENT_COLOR2: 'Accent Color2',
  ACCENT_COLOR3: 'Accent Color3',
  BODY_COLOR: 'Body Color',
} as Record<string, string>

const generateCylindoFeatureParamsImageUrl = (options: IActiveOptions[]) => {
  const url: string[] = []
  options.forEach((op: IActiveOptions) => {
    if (op.code) {
      url.push(`feature=${cylindoCodes[op.code]}:${op.value}`)
    }
  })
  return url.join('&')
}

const generateCylindoProductUrl = (options: ICustomActiveOptions[]) => {
  const url: string[] = []
  options.forEach(op => {
    url.push(`${op.code.toLowerCase()}=${encodeURIComponent(op.value.toLowerCase().replace(SPACE_REGEX, '-'))}`)
  })
  return url.join('&')
}

const concatCustomOptionValueIds = (selectedCustomAddons: ICustomActiveOptions[]) => {
  const concatIds: number[] = []
  selectedCustomAddons.forEach((op: ICustomActiveOptions) => {
    if (op.customOptionValueId) {
      concatIds.push(op.customOptionValueId)
    }
  })
  return concatIds.sort().join('_')
}

const getDefaultImage = (item: ICartItem) => {
  let thumbnail = item.product.thumbnail
  if (item?.metaData?.cylindoConfig) {
    thumbnail = item.metaData.cylindoConfig.thumbnail
  }
  return thumbnail ? fit(thumbnail, { size: 300 }) : '/static/morPlaceholder.jpg'
}

const getCustomItemMetadata = (item: ICartItem) => {
  if (item.metaData && isCustomProductMetadata(item.metaData)) {
    return item.metaData.cylindoConfig.selectedOptions.map((addon: IActiveOptions, index: number) => {
      return (
        addon.code && (
          <div key={index}>
            {cylindoLabels[addon.code]}: {getColorValueFromCylindoMaterialCdAndColor(addon.value)}
          </div>
        )
      )
    })
  }
}

const isCustomProduct = (product: IPDPProduct) => {
  return product?.variants?.[0].CUSTOM_SKU === 'Y'
}

const isCustomProductMetadata = (metaData: { [x: string]: any }) => {
  return metaData?.cylindoConfig?.selectedOptions?.length && metaData.cylindoConfig.custom
}

const capitalize = (value?: string) => {
  if (value) {
    const stringArray = value.split(' ')

    //loop through each element of the array and capitalize the first letter.

    for (let i = 0; i < stringArray.length; i++) {
      stringArray[i] = stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1)
    }

    //Join all the elements of the array back into a string
    //using a blankspace as a separator
    const capitalizedString = stringArray.join(' ')
    return capitalizedString
  }
  return value
}

const getSelectedOptionsInitialState = (product: IPDPProduct) => {
  const selectedOptionsInitialState: ICustomActiveOptions[] = []
  const { groupSwatches } = product
  if (groupSwatches) {
    // init with query params
    const cylindoMaterialCd = getCustomProductCylindoMaterialCd(product)
    if (location?.search) {
      const queryParams = queryString.parse(location.search)
      for (const param in queryParams) {
        const colorSwatches = groupSwatches.find(groupSwatch => groupSwatch.code === param.toUpperCase())

        const queryParamValue = capitalize(
          getColorValueFromCylindoMaterialCdAndColor((queryParams[param] as any).toString()).replace(HYPHEN_REGEX, ' '),
        )

        const swatch = colorSwatches?.swatches.find(swatch => swatch.option === queryParamValue)
        if (swatch?.customOptionValueId) {
          selectedOptionsInitialState.push({
            code: param.toUpperCase(),
            value: queryParamValue ? getCustomProductColorValue(cylindoMaterialCd, queryParamValue) : queryParamValue,
            customOptionValueId: swatch?.customOptionValueId,
          })
        }
      }
    } else {
      // init with the first color of the body color
      // First body color
      const bodyColorSwatches = groupSwatches.find(groupSwatch => groupSwatch.code === ICustomAddons.BODY_COLOR)
      if (bodyColorSwatches) {
        selectedOptionsInitialState.push({
          code: bodyColorSwatches?.code,
          value: getCustomProductColorValue(cylindoMaterialCd, bodyColorSwatches?.swatches[0].option),
          customOptionValueId: bodyColorSwatches?.swatches[0].customOptionValueId,
        })
        // Get the same color of the body color for the accent color
        getAccentColorInitialState(
          groupSwatches,
          ICustomAddons.ACCENT_COLOR,
          selectedOptionsInitialState,
          bodyColorSwatches,
          cylindoMaterialCd,
        )
        getAccentColorInitialState(
          groupSwatches,
          ICustomAddons.ACCENT_COLOR2,
          selectedOptionsInitialState,
          bodyColorSwatches,
          cylindoMaterialCd,
        )
        getAccentColorInitialState(
          groupSwatches,
          ICustomAddons.ACCENT_COLOR3,
          selectedOptionsInitialState,
          bodyColorSwatches,
          cylindoMaterialCd,
        )
      } else {
        const accentColorSwatches = groupSwatches.find(groupSwatch => groupSwatch.code === ICustomAddons.ACCENT_COLOR)
        if (accentColorSwatches) {
          selectedOptionsInitialState.push({
            code: accentColorSwatches?.code,
            value: getCustomProductColorValue(cylindoMaterialCd, accentColorSwatches?.swatches[0].option),
            customOptionValueId: accentColorSwatches?.swatches[0].customOptionValueId,
          })
        }
      }
    }
  }

  return selectedOptionsInitialState
}

const getAccentColorInitialState = (
  groupSwatchs: IAttributeSwatch[],
  swatchCode: ICustomAddons,
  selectedOptionsInitialState: ICustomActiveOptions[],
  bodyColorSwatches: IAttributeSwatch,
  cylindoMaterialCd: string | null,
) => {
  const accentColorSwatch = groupSwatchs.find(groupSwatch => groupSwatch.code === swatchCode)

  if (accentColorSwatch) {
    const initAccentColor = accentColorSwatch?.swatches.find(
      swatch => swatch.option === bodyColorSwatches?.swatches[0].option,
    )

    if (initAccentColor?.customOptionValueId) {
      selectedOptionsInitialState.push({
        code: accentColorSwatch?.code,
        value: getCustomProductColorValue(cylindoMaterialCd, initAccentColor?.option),
        customOptionValueId: initAccentColor?.customOptionValueId,
      })
    }
  }
}

const getCustomProductCylindoMaterialCd = (product: IPDPProduct) => {
  let cylindoMaterialCd = null
  if (product.CYLINDO_MATERIAL_CD) {
    cylindoMaterialCd = product.CYLINDO_MATERIAL_CD.substring(0, product.CYLINDO_MATERIAL_CD.indexOf('>'))
  }

  return cylindoMaterialCd
}

const getCustomProductColorValue = (cylindoMaterialCd: string | null, colorSwatchOption: string) => {
  return cylindoMaterialCd ? `${cylindoMaterialCd}>${colorSwatchOption}` : colorSwatchOption
}

/**
 * For cylindoMaterialCdColor the format is the following: Michael Nicholas>Curious Sand
 * So we need to extract the Courios Sand value
 * @param cylindoMaterialCdAndColor Michael Nicholas>Curious Sand
 * @returns Curious Sand
 */
const getColorValueFromCylindoMaterialCdAndColor = (cylindoMaterialCdAndColor: string) => {
  if (cylindoMaterialCdAndColor?.includes('>')) {
    return cylindoMaterialCdAndColor.split('>')[1]
  }
  return cylindoMaterialCdAndColor
}

const getSkuFromCartItem = (item: ICartItem) => {
  let sku = item.product.sku || item.product.id
  if (sku.includes('_')) {
    sku = sku.split('_')[0]
  }
  return sku
}

const generateProductIdForCartItem = (item: ICartItem) => {
  let productId = item.product.sku
  if (item.metaData && isCustomProductMetadata(item.metaData)) {
    productId = `${productId}_${concatCustomOptionValueIds(item.metaData?.cylindoConfig?.selectedOptions)}`
  }
  return productId
}

export {
  generateCylindoProductUrl,
  generateCylindoFeatureParamsImageUrl,
  getDefaultImage,
  cylindoLabels,
  getCustomItemMetadata,
  CUSTOM_ADDON_GROUP_CODE,
  getSelectedOptionsInitialState,
  isCustomProduct,
  isCustomProductMetadata,
  concatCustomOptionValueIds,
  getSkuFromCartItem,
  generateProductIdForCartItem,
  cylindoCodes,
  getCustomProductCylindoMaterialCd,
  getCustomProductColorValue,
  getColorValueFromCylindoMaterialCdAndColor,
  capitalize,
}
