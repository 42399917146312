import React, { useEffect, useState } from 'react'
import css from './ChatJumpLink.scss'

const ChatJumpLink = () => {
  const [showChatJumpLink, setShowChatJumpLink] = useState(false)

  useEffect(() => {
    checkPodiumChatHasBeenLoaded()
  })

  const checkPodiumChatHasBeenLoaded = () => {
    const sdkCheck = setInterval(() => {
      if (window.PodiumWebChat) {
        clearInterval(sdkCheck)
        setShowChatJumpLink(true)
      }
    }, 2000)
  }

  return showChatJumpLink ? (
    <div id="ABTastyElement3480107">
      <div style={{ animationDelay: '0ms' }}>
        <div>
          <div className="font-weight--600 text-center font-size--14 line-height--28 px-lg-4 px-md-4 px-sm-0 px-xs-0">
            <p>Questions About This Product?</p>
          </div>
        </div>
        <div>
          <div className="text-center">
            <p className={css.link} onClick={() => window.PodiumWebChat.open()}>
              <u>Chat With Us</u>
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ChatJumpLink
