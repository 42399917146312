import { ITransport } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import CACHE_CONTSTANT from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/cache/constants'
import { IStoreLocation } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'

const ENDPOINTS = {
  ONE_LOCATION: `locations?slug=`,
  ONE_LOCATION_BY_STORE_CODE: `locations?legacyStoreId=`,
}

// ----------------------------------------- one location --------------------------------------------
const getSingleLocation = async (transport: ITransport, slug: string | string[]) => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.ONE_LOCATION}${slug}`
    const response = await transport.get(finalEndpoint, {
      headers: { cacheKey: `${CACHE_CONTSTANT.FRONTEND.MISC.LOCATIONS}-slug-${slug}` },
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occured',
      data: null,
    }
  }
}

const getWarehouseLocations = async (transport: ITransport): Promise<IStoreLocation[]> => {
  const response = await transport.get('/locations?', {
    headers: { cacheKey: `${CACHE_CONTSTANT.FRONTEND.MISC.LOCATIONS}` },
  })

  return response.data.filter((item: IStoreLocation) => {
    return item?.metadata?.showroomPickupType === 'B' || item?.metadata?.showroomPickupType === 'W'
  })
}

const getSingleLocationByStoreCode = async (transport: ITransport, storeCode: string) => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.ONE_LOCATION_BY_STORE_CODE}${storeCode}`
    const response = await transport.get(finalEndpoint, {
      headers: { cacheKey: `${CACHE_CONTSTANT.FRONTEND.MISC.LOCATIONS}-storeCode-${storeCode}` },
    })
    return {
      error: '',
      data: response.data[0],
    }
  } catch (e) {
    return {
      error: 'An error has occured',
      data: null,
    }
  }
}

export default {
  getSingleLocation,
  getWarehouseLocations,
  getSingleLocationByStoreCode,
}
